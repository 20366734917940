'use client';

import NextLink, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type NavigationLinkClickTrackingProperties } from '@/types/tracking';

const defaultStyles = cn('group transition-colors text-brand-blue-yves no-underline hover:text-analyst-dark-lavender');

export type NavigationLinkProps = LinkProps & {
    target?: string;
    children: ReactNode;
    className?: string;
    tabIndex?: number;
    title?: string;
    rel?: string;
    trackingProperties?: Omit<NavigationLinkClickTrackingProperties, 'component' | 'href'>;
};

export const NavigationLink = ({
    children,
    className,
    trackingProperties = {} as Omit<NavigationLinkClickTrackingProperties, 'component' | 'href'>,
    ...passThroughProps
}: NavigationLinkProps) => {
    const { eventTypes, components, trackNavigationLinkClick } = usePosthogTracking();
    const trackingAction = () => {
        trackNavigationLinkClick({
            eventType: eventTypes.INTERNAL_LINK_CLICK,
            trackingProperties: {
                ...trackingProperties,
                component:
                    passThroughProps.target === '_blank'
                        ? components.EXTERNAL_NAVIGATION_LINK
                        : components.INTERNAL_NAVIGATION_LINK,
                href: passThroughProps.href as string,
            },
        });
    };

    return (
        <NextLink
            className={cn(defaultStyles, className)}
            {...passThroughProps}
            onClick={trackingAction}
        >
            {children}
        </NextLink>
    );
};

type ActionLinkProps = {
    children: ReactNode;
    className?: string;
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    title?: string;
};

export const ActionLink = ({ children, className, onClick, title }: ActionLinkProps) => {
    return (
        <a
            className={cn(defaultStyles, className)}
            {...{ onClick, title }}
        >
            {children}
        </a>
    );
};
