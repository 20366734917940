import Head from 'next/head';

const Meta = () => {
    const favIconPath = process.env.NEXT_PUBLIC_FAVICON_PATH ?? 'favicon';

    return (
        <Head>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`/${favIconPath}/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`/${favIconPath}/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`/${favIconPath}/favicon-16x16.png`}
            />
            <link
                rel="manifest"
                href={`/${favIconPath}/site.webmanifest`}
            />
            <link
                rel="mask-icon"
                href={`/${favIconPath}/safari-pinned-tab.svg`}
                color="#000000"
            />
            <link
                rel="shortcut icon"
                href={`/${favIconPath}/favicon.ico`}
            />
            <meta
                name="msapplication-TileColor"
                content="#000000"
            />
            <meta
                name="msapplication-config"
                content={`/${favIconPath}/browserconfig.xml`}
            />
            <meta
                name="theme-color"
                content="#000000"
            ></meta>

            <link
                rel="alternate"
                type="application/rss+xml"
                href="/feed.xml"
            />
            <meta
                name="description"
                content={``}
            />
            {/* below is the image used when sharing */}
            {/* <meta property="og:image" content={HOME_OG_IMAGE_URL} /> */}
        </Head>
    );
};

export default Meta;
