import { gql } from 'graphql-request';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { FieldError, Label, SubmitButton, TextInput } from '@/components/dom/form-elements';
import TOSMessaging from '@/components/global/tos-messaging';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { GQL_CLIENT } from '@/lib/graphql';
import * as gtag from '@/lib/gtag';
import { BaseTrackingProperties } from '@/types/tracking';

const SIGNUP_QUERY = gql`
    mutation createUserAndOrganization($email: String!, $name: String!, $inviteId: ID) {
        createUserAndOrganization(input: { email: $email, name: $name, inviteId: $inviteId }) {
            __typename
            ... on CreateUserSuccess {
                userId
                loginAttemptId
            }
            ... on FieldErrors {
                errors {
                    field
                    message
                }
            }
        }
    }
`;

interface SignupFormProps {
    onSuccess?: (loginAttemptId: string, email: string) => void;
    initialEmail?: string;
    inviteId?: string;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
}

export const SignupForm = ({
    onSuccess,
    initialEmail = '',
    inviteId,
    trackingProperties = {} as BaseTrackingProperties,
}: SignupFormProps) => {
    const { categories, components, eventTypes, trackManualEvent } = usePosthogTracking();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const [emailError, setEmailError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError('');
    };
    const onNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError('');
    };

    const submitSignUpForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = { email, inviteId, name };

        trackManualEvent({
            eventType: eventTypes.SIGNUP_FORM_SUBMIT,
            trackingProperties: {
                ...trackingProperties,
                additionalTrackingProperties: { email, inviteId, name },
                category: categories.LOGIN_SIGNUP,
                component: components.SIGNUP_FORM,
            },
        });

        const data: {
            createUserAndOrganization:
                | {
                      __typename: 'FieldErrors';
                      errors: Array<{
                          field: string;
                          message: string;
                      }>;
                  }
                | {
                      __typename: 'CreateUserSuccess';
                      userId: Optional<string>;
                      loginAttemptId: Optional<string>;
                  };
        } = await GQL_CLIENT.request(SIGNUP_QUERY, variables);
        const result = data.createUserAndOrganization;

        const success = result.__typename === 'CreateUserSuccess';

        if (success) {
            onSuccess?.(result.loginAttemptId || '', email);
            trackManualEvent({
                eventType: eventTypes.SIGNUP_SUCCESS,
                trackingProperties: {
                    ...trackingProperties,
                    category: categories.LOGIN_SIGNUP,
                    component: components.SIGNUP_FORM,
                },
            });
        } else {
            result.errors.forEach((value: { field: string; message: string }) => {
                if (value.field === 'email_address') {
                    setEmailError(value.message);

                    trackManualEvent({
                        eventType: eventTypes.SIGNUP_ERROR,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.SIGNUP_FORM,
                            errorType: 'email',
                            message: value.message,
                        },
                    });
                } else if (value.field === 'name') {
                    setNameError(value.message);

                    trackManualEvent({
                        eventType: eventTypes.SIGNUP_ERROR,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.SIGNUP_FORM,
                            errorType: 'name',
                            message: value.message,
                        },
                    });
                } else {
                    toast.error(value.message);

                    trackManualEvent({
                        eventType: eventTypes.SIGNUP_ERROR,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.SIGNUP_FORM,
                            errorType: 'other',
                            message: value.message,
                        },
                    });
                }
            });
        }

        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Sign Up',
            label: 'Submit',
        });

        setIsSubmitting(false);
    };

    return (
        <form
            onSubmit={submitSignUpForm}
            noValidate
        >
            <div className="mb-6">
                <Label
                    text="Email"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        className="w-full"
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={email}
                        onChange={onEmailInput}
                        required={true}
                        spellCheck={false}
                        placeholder="someonecool@yourorganization.com"
                        autoFocus={true}
                    />
                </Label>
                <FieldError message={emailError} />
            </div>
            <div className="mb-6">
                <Label
                    text="Name"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        className="w-full"
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={name}
                        onChange={onNameInput}
                        required={true}
                        spellCheck={false}
                        placeholder="First and last name"
                        testId="sign-up-name"
                    />
                </Label>
                <FieldError message={nameError} />
            </div>
            <TOSMessaging clickedString="Create account" />
            <SubmitButton
                isDisabled={isSubmitting}
                value="Create account"
                roundedCorners="full"
                className="font-brand-md mx-0 w-full"
                color="primary-gradient"
            />
        </form>
    );
};
