import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';

import { Select, SingleSelectedState } from '@/components/dom/form-elements';
import { GQL_CLIENT } from '@/lib/graphql';

type OrganizationType = {
    id: string;
    name: string;
};

const ALL_ORGANIZATIONS_QUERY = gql`
    query {
        organizations {
            id
            name
        }
    }
`;

const OrganizationSelector = ({
    selectedOrganizationId,
    defaultOrganizationId,
    onSelect,
}: {
    selectedOrganizationId: string;
    defaultOrganizationId: string | undefined;
    onSelect: (organizationId: string) => void;
}) => {
    const [organizations, setOrganizations] = useState<Array<OrganizationType>>([]);

    useEffect(() => {
        GQL_CLIENT.request(ALL_ORGANIZATIONS_QUERY).then((data: { organizations: Array<OrganizationType> }) => {
            const organizations = data.organizations;
            organizations.sort((a: OrganizationType, b: OrganizationType) => {
                const aValue = [a.id === defaultOrganizationId ? -1 : 0, a.name.toLowerCase()];
                const bValue = [b.id === defaultOrganizationId ? -1 : 0, b.name.toLowerCase()];

                return aValue > bValue ? 1 : -1;
            });
            setOrganizations(organizations);
        });
    }, [defaultOrganizationId]);

    const options = organizations.map((organization) => {
        return {
            label: organization.name,
            value: organization.id,
        };
    });

    return (
        <Select
            options={options}
            isSearchable={true}
            isDisabled={organizations.length <= 1}
            value={options.find((option) => option.value === selectedOrganizationId)}
            onChange={(data) => {
                const organizationId = (data as SingleSelectedState)?.value as string;
                onSelect(organizationId);
            }}
        />
    );
};

export default OrganizationSelector;
