'use client';

import React, { createContext, useContext, useState } from 'react';

import { CurrentUserType } from '@/queries/current-user';

export interface GlobalStateInterface {
    currentUser?: CurrentUserType | null;
    currentUserLoading: boolean;
}

const defaultGlobalState = {
    currentUser: undefined,
    currentUserLoading: true,
};

const GlobalStateContext = createContext({
    globalState: {} as Partial<GlobalStateInterface>,
    setGlobalState: {} as ReactStateSetter<Partial<GlobalStateInterface>>,
});

const GlobalStateProvider = ({
    children,
    value = { ...defaultGlobalState } as GlobalStateInterface,
}: {
    children: React.ReactNode;
    value?: Partial<GlobalStateInterface>;
}) => {
    const [globalState, setGlobalState] = useState(value);
    return (
        <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>{children}</GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalStateContext');
    }
    return context;
};

export { GlobalStateProvider, useGlobalState };
