import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { NextIncomingMessage } from 'next/dist/server/request-meta';

export type AbortSignal = NonNullable<RequestInit['signal']>;

import { API_ADMIN_GRAPHQL_ENDPOINT, API_GRAPHQL_ENDPOINT, BUILD_GRAPHQL_ENDPOINT } from '@/lib/constants';

export const ABORT_CONTROLLER = new AbortController();

export const GQL_CLIENT = new GraphQLClient(API_GRAPHQL_ENDPOINT, {
    credentials: 'include',
    mode: 'cors',
    signal: ABORT_CONTROLLER.signal as AbortSignal,
});

export const ADMIN_GQL_CLIENT = new GraphQLClient(API_ADMIN_GRAPHQL_ENDPOINT, {
    credentials: 'include',
    mode: 'cors',
    signal: ABORT_CONTROLLER.signal as AbortSignal,
});

/**
 * GraphQLClient for use with getServerSideProps.
 *
 * While making server side requests we want to pass the headers from the browser through the server so auth still works.
 */
export const BUILD_SERVER_SIDE_PROPS_GQL_CLIENT = (headers: NextIncomingMessage['headers']): GraphQLClient => {
    return new GraphQLClient(BUILD_GRAPHQL_ENDPOINT, {
        credentials: 'include',
        headers: headers as HeadersInit,
        mode: 'cors',
        signal: ABORT_CONTROLLER.signal as AbortSignal,
    });
};
