'use client';

import React, { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import ThematicCubeLogo from '@/assets/logos/thematic-cube.svg';
import ThematicLogo from '@/assets/logos/thematic-cube-logo.svg';
import { NavigationLink } from '@/components/global/link';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type NavigationLinkClickTrackingProperties } from '@/types/tracking';

interface ThematicLogoLinkProps {
    className?: string;
    iconClassName?: string;
    iconInnerWrapperClassName?: string;
    condenseLogo?: boolean;
    tabIndex?: number;
    useCubeLogo?: boolean;
    condenseLogoOnMobile?: boolean;
    trackingProperties?: Pick<NavigationLinkClickTrackingProperties, 'section'>;
}

const ThematicLogoLink = ({
    className,
    iconClassName,
    iconInnerWrapperClassName,
    condenseLogo = false,
    tabIndex,
    useCubeLogo = false,
    trackingProperties = {} as NavigationLinkClickTrackingProperties,
}: ThematicLogoLinkProps) => {
    const { categories, components, eventTypes, trackNavigationLinkClick } = usePosthogTracking();
    const [condenseLogoState, setCondenseLogoState] = useState(condenseLogo);
    const previousCondenseLogoState = usePrevious(condenseLogo);

    useEffect(() => {
        if (previousCondenseLogoState !== undefined && previousCondenseLogoState !== condenseLogo) {
            setCondenseLogoState(condenseLogo);
        }
    }, [previousCondenseLogoState, condenseLogoState, condenseLogo]);
    const Logo = useMemo(() => (useCubeLogo ? ThematicCubeLogo : ThematicLogo), [useCubeLogo]);
    const trackingAction = () => {
        trackNavigationLinkClick({
            eventType: eventTypes.INTERNAL_LINK_CLICK,
            trackingProperties: {
                ...trackingProperties,
                category: categories.NAVIGATION_LINK,
                component: components.THEMATIC_LOGO_LINK,
                href: '/',
                iconLogo: 'thematic_logo_with_cube',
            },
        });
    };

    return (
        <NavigationLink
            href="/"
            onClick={trackingAction}
            className={cn('h-7', className)}
            tabIndex={tabIndex}
            title="Thematic"
        >
            <span
                className={cn(
                    'grid h-full relative overflow-hidden w-36 transition-width duration-500',
                    iconInnerWrapperClassName,
                    {
                        'w-6 transition-width duration-500': condenseLogoState,
                    }
                )}
            >
                <Logo className={cn('h-full text-white', iconClassName)} />
            </span>
            <span className="sr-only">Thematic Home</span>
        </NavigationLink>
    );
};

export default ThematicLogoLink;
