'use client';

import { gql } from 'graphql-request';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { MultiSelectedState } from '@/components/dom/form-elements';
import { useCurrentOrganization } from '@/components/global/current-organization';
import { useGlobalState } from '@/components/global/global-state';
import { BuildFundamentalInputDefaults, FundamentalsType } from '@/components/index-builder/fundamental-inputs';
import { RoundToDecimals } from '@/helpers/numbers';
import { DEFAULT_INDEX_BUILDER_BENCHMARK_INDEX_IDS } from '@/lib/constants';
import { clearContextLocalStorage, makeUseContextLocalStorage } from '@/lib/contextLocalStorage';
import { GQL_CLIENT } from '@/lib/graphql';
import { asBasicStrategy, isRulesBasedStrategy, StrategyType, WeightingStrategyType } from '@/types/index';
import { InstrumentType } from '@/types/instrument';

const CONTEXT_BASE = 'indexBuilder';

export interface IndexBuilderContextInterface {
    benchmarkInstrumentIds: Array<string>;
    benchmarkMyIndexIds: Array<string>;
    benchmarkPublicIndexIds: Array<string>;
    benchmarkThematicIndexIds: Array<string>;
    builderIndexId: string;
    canUpdateName: boolean;
    clearIndexBuilderContext: () => void;
    companyEnabled: boolean;
    cryptoEnabled: boolean;
    currentStrategy: StrategyType;
    currentStrategyId: number;
    definedFundamentals: FundamentalsType;
    description: string;
    etfEnabled: boolean;
    exportToIndexBuilder: (params: ExportToIndexBuilderParams) => void;
    hasRebalanceSchedule: boolean;
    hasRulesBasedStrategy: boolean;
    isCreateFlow: boolean;
    isHidden: boolean;
    isPopulatingFieldsFromIndex: boolean;
    isPortfolio: boolean;
    name: string;
    originatingAnalystThemeRequestId: string;
    populateFieldsFromIndexId: (indexId: string) => void;
    resetIndexBuilderContext: () => void;
    searchValue: string;
    selectedCountries: MultiSelectedState;
    selectedIndustries: MultiSelectedState;
    selectedRegions: MultiSelectedState;
    selectedSectors: MultiSelectedState;
    selectedSecurities: Array<string>;
    setBenchmarkInstrumentIds: ReactStateSetter<Array<string>>;
    setBenchmarkMyIndexIds: ReactStateSetter<Array<string>>;
    setBenchmarkPublicIndexIds: ReactStateSetter<Array<string>>;
    setBenchmarkThematicIndexIds: ReactStateSetter<Array<string>>;
    setCompanyEnabled: ReactStateSetter<boolean>;
    setCryptoEnabled: ReactStateSetter<boolean>;
    setCurrentStrategyId: ReactStateSetter<number>;
    setDefinedFundamentals: ReactStateSetter<FundamentalsType>;
    setDescription: ReactStateSetter<string>;
    setEtfEnabled: ReactStateSetter<boolean>;
    setHasRebalanceSchedule: ReactStateSetter<boolean>;
    setIsHidden: ReactStateSetter<boolean>;
    setIsPortfolio: ReactStateSetter<boolean>;
    setName: ReactStateSetter<string>;
    setOriginatingAnalystThemeRequestId: ReactStateSetter<string>;
    setSearchValue: ReactStateSetter<string>;
    setSelectedCountries: ReactStateSetter<MultiSelectedState>;
    setSelectedIndustries: ReactStateSetter<MultiSelectedState>;
    setSelectedRegions: ReactStateSetter<MultiSelectedState>;
    setSelectedSectors: ReactStateSetter<MultiSelectedState>;
    setStrategies: ReactStateSetter<Array<StrategyType>>;
    setSymbol: ReactStateSetter<string>;
    strategies: Array<StrategyType>;
    symbol: string;
    useNrr: boolean;
}

const IndexBuilderContext = createContext({} as IndexBuilderContextInterface);

type IndexType = {
    id: string;
    symbol: string;
    name: string;
    canUpdateName: boolean;
    isPortfolio: boolean;
    isHidden: boolean;
    activeVersion: {
        id: string;
        indexName: string;
        indexDescription: string;
        indexBenchmarkIndexes: Array<{
            id: string;
        }>;
        hasRebalanceSchedule: boolean;
        // TODO: handle Rules Based
        strategy:
            | {
                  __typename: 'EqualWeightedStrategy' | 'MarketCapWeightedStrategy' | 'RootMarketCapWeightedStrategy' | 'PriceWeightedStrategy';
                  components: Array<{
                      id: string;
                  }>;
              }
            | {
                  __typename: 'CustomWeightedStrategy';
                  weightedComponents: Array<{
                      component: {
                          id: string;
                      };
                      weight: string;
                  }>;
              }
            | {
                  __typename: 'RulesBasedEqualWeightedStrategy';
              };
    };
};

const queryIndex = (indexId: string): Promise<{ index: IndexType }> => {
    const indexQuery = gql`
        query getIndex($id: ID!) {
            index(id: $id) {
                id
                symbol
                name
                canUpdateName
                isPortfolio
                activeVersion {
                    id
                    indexName
                    indexDescription
                    indexBenchmarkIndexes {
                        id
                    }
                    hasRebalanceSchedule
                    strategy {
                        __typename
                        ... on StaticComponentStrategy {
                            components {
                                id
                            }
                        }
                        ... on CustomWeightedStrategy {
                            weightedComponents {
                                component {
                                    id
                                }
                                weight
                            }
                        }
                    }
                }
            }
        }
    `;
    return GQL_CLIENT.request(indexQuery, { id: indexId });
};

type CreateOrUpdateIndexDraftResultType =
    | {
          __typename: 'FieldErrors';
          errors: Array<{
              field: string;
              message: string;
          }>;
      }
    | {
          __typename: 'IndexDraft';
          token: string;
          versionId: number;
      };

interface ExportToIndexBuilderParams {
    analysis: string;
    requestId: string;
    selectedInstrumentResults:
        | InstrumentType
        | Array<{
              instrument: {
                  id: string;
              };
          }>;
    themeName: string;
}

const createOrUpdateDraftIndexState = (
    draftToken: string,
    organizationId: string,
    draftIndexState: Record<string, unknown>
): Promise<{ createOrUpdateIndexDraft: CreateOrUpdateIndexDraftResultType }> => {
    const indexQuery = gql`
        mutation CreateOrUpdateIndexDraft($token: String!, $organizationId: ID, $indexBlob: String!) {
            createOrUpdateIndexDraft(input: { token: $token, organizationId: $organizationId, indexBlob: $indexBlob }) {
                __typename
                ... on IndexDraft {
                    token
                    versionId
                }
            }
        }
    `;
    return GQL_CLIENT.request(indexQuery, { indexBlob: JSON.stringify(draftIndexState), organizationId: organizationId || null, token: draftToken });
};

let createOrUpdateIndexDraftTimer: NodeJS.Timeout | number | undefined;

const IndexBuilderContextProvider = ({ rawBuilderIndexId, children }: { rawBuilderIndexId: string; children: React.ReactNode }) => {
    const builderIndexId = rawBuilderIndexId === 'create' ? 'create' : rawBuilderIndexId.toUpperCase();

    // TODO: nest this instead of concatenating
    const contextLabel = CONTEXT_BASE + ':' + builderIndexId;

    const useLocalStorage = makeUseContextLocalStorage(contextLabel);

    const [benchmarkPublicIndexIds, setBenchmarkPublicIndexIds] = useLocalStorage<Array<string>>('benchmarkPublicIndexIds', DEFAULT_INDEX_BUILDER_BENCHMARK_INDEX_IDS);
    const [benchmarkThematicIndexIds, setBenchmarkThematicIndexIds] = useLocalStorage<Array<string>>('benchmarks', []);
    const [benchmarkMyIndexIds, setBenchmarkMyIndexIds] = useLocalStorage<Array<string>>('benchmarksMine', []);
    const [benchmarkInstrumentIds, setBenchmarkInstrumentIds] = useLocalStorage<Array<string>>('benchmarkInstrumentIds', []);
    const [definedFundamentals, setDefinedFundamentals] = useLocalStorage<FundamentalsType>('definedFundamentals', BuildFundamentalInputDefaults());
    const [description, setDescription] = useLocalStorage('description', '');
    const [name, setName] = useLocalStorage('name', '');
    const [searchValue, setSearchValue] = useLocalStorage('searchValue', '');
    const [companyEnabled, setCompanyEnabled] = useLocalStorage<boolean>('companyEnabled', true);
    const [cryptoEnabled, setCryptoEnabled] = useLocalStorage<boolean>('cryptoEnabled', false);
    const [etfEnabled, setEtfEnabled] = useLocalStorage<boolean>('etfEnabled', true);
    const [isHidden, setIsHidden] = useLocalStorage<boolean>('isHidden', true);
    const [isPortfolio, setIsPortfolio] = useLocalStorage<boolean>('isPortfolio', false);
    const [selectedSectors, setSelectedSectors] = useLocalStorage<MultiSelectedState>('selectedSectors', []);
    const [selectedIndustries, setSelectedIndustries] = useLocalStorage<MultiSelectedState>('selectedIndustries', []);
    const [selectedRegions, setSelectedRegions] = useLocalStorage<MultiSelectedState>('selectedRegions', []);
    const [selectedCountries, setSelectedCountries] = useLocalStorage<MultiSelectedState>('selectedCountries', []);

    // Name mismatch for legacy reasons. TODO: perform transition without invalidating sessions in progress
    const [symbol, setSymbol] = useLocalStorage<string>('symbol', '');

    const defaultStrategyId = new Date().getTime();

    const [strategies, setStrategies] = useLocalStorage<Array<StrategyType>>('strategies', [
        {
            id: defaultStrategyId,
            name: 'Default Strategy',
            securityIds: [],
            weightingType: WeightingStrategyType.equal,
        },
    ]);

    const selectedSecurities = [
        ...new Set(strategies.map(asBasicStrategy).reduce((acc: Array<string>, strategy) => acc.concat(strategy ? strategy.securityIds : []), [])),
    ];
    const [currentStrategyId, setCurrentStrategyId] = useLocalStorage<number>('currentStrategyId', defaultStrategyId);

    const currentStrategy = useMemo(() => {
        const newCurrent = strategies.find((strategy) => currentStrategyId === strategy.id);
        if (newCurrent) {
            return newCurrent;
        } else {
            // we couldn't find it so set the current to the last one on the list
            const lastStrategy = strategies[strategies.length - 1];
            setCurrentStrategyId(lastStrategy.id);
            return lastStrategy;
        }
    }, [currentStrategyId, strategies, setCurrentStrategyId]);

    const [baseIndexVersionId, setBaseIndexVersionId] = useLocalStorage('baseIndexVersionId', '');
    const [originatingAnalystThemeRequestId, setOriginatingAnalystThemeRequestId] = useLocalStorage('originatingAnalystThemeRequestId', '');

    const isCreateFlow = builderIndexId === 'create';
    const [canUpdateName, setCanUpdateName] = useLocalStorage('canUpdateName', true);
    const [isPopulatingFieldsFromIndex, setIsPopulatingFieldsFromIndex] = useState(true);

    // Legacy name
    const [draftToken] = useLocalStorage<string>('draftId', uuidv4());
    // TODO: use `draftVersionId` as part of requesting updates
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [draftVersionId, setDraftVersionId] = useLocalStorage<number>('draftVersionId', 0);
    const [draftLastState, setDraftLastState] = useLocalStorage<string>('draftLastState', '');
    const [hasRebalanceSchedule, setHasRebalanceSchedule] = useLocalStorage<boolean>('hasRebalanceSchedule', true);
    const { globalState } = useGlobalState();
    const { currentUser } = globalState;
    const { currentOrganizationId } = useCurrentOrganization();

    // Surface NRR as searchable and displayed fundamental
    const useNrr = symbol === 'LENNY';

    const clearIndexBuilderContext = () => {
        clearContextLocalStorage(contextLabel);
    };

    const populateFieldsFromIndex = useCallback(
        (index: IndexType) => {
            const { activeVersion } = index;

            if (isCreateFlow) {
                setName(`Copy of ${index.name}`);
                setSymbol('');
                setCanUpdateName(true);
            } else {
                setName(index.name);
                setSymbol(index.symbol);
                setCanUpdateName(index.canUpdateName);
                setIsPortfolio(index.isPortfolio);
                setIsHidden(index.isHidden);
            }

            if (!activeVersion) {
                // There's no active version, so start with a blank slate
                // TODO: set a sentinel for `baseIndexVersionId` so we can check it to avoid this query path on subsequent loads in the same edit flow
                return;
            }

            const { id, indexDescription, indexBenchmarkIndexes, hasRebalanceSchedule, strategy } = activeVersion;

            // TODO: populate other state fields from activeVersion
            setDescription(indexDescription);
            if (indexBenchmarkIndexes.length > 0) {
                setBenchmarkPublicIndexIds(indexBenchmarkIndexes.map((x) => x.id));
            }
            setHasRebalanceSchedule(hasRebalanceSchedule);

            // TODO: handle Rules Based
            const newStrategy: StrategyType = {
                id: new Date().getTime(),
                name: isCreateFlow ? `Copy of ${index.name}` : index.name,
                ...(() => {
                    switch (strategy.__typename) {
                        case 'CustomWeightedStrategy':
                            return {
                                securityIds: strategy.weightedComponents.map((x) => x.component.id),
                                weightValues: strategy.weightedComponents.reduce((acc, weightedComponent) => {
                                    return { ...acc, [weightedComponent.component.id]: parseFloat(RoundToDecimals(2, parseFloat(weightedComponent.weight))) };
                                }, {}),
                                weightingType: WeightingStrategyType.custom,
                            };
                        case 'EqualWeightedStrategy':
                            return {
                                securityIds: strategy.components.map((x) => x.id),
                                weightingType: WeightingStrategyType.equal,
                            };
                        case 'MarketCapWeightedStrategy':
                            return {
                                securityIds: strategy.components.map((x) => x.id),
                                weightingType: WeightingStrategyType.marketCap,
                            };
                        case 'RootMarketCapWeightedStrategy':
                            return {
                                securityIds: strategy.components.map((x) => x.id),
                                weightingType: WeightingStrategyType.rootMarketCap,
                            };
                        case 'PriceWeightedStrategy':
                            return {
                                securityIds: strategy.components.map((x) => x.id),
                                weightingType: WeightingStrategyType.price,
                            };
                        case 'RulesBasedEqualWeightedStrategy':
                            // TODO: implement this
                            throw new Error(`Clone not implemented for Rules Based strategies`);
                        default:
                            // TODO: typescript prevents us from accessing `strategy.__typename` here
                            throw new Error(`Unexpected strategy ${strategy}`);
                    }
                })(),
            };
            // set the selected securities
            setStrategies([newStrategy]);
            setCurrentStrategyId(newStrategy.id);

            if (isCreateFlow) {
                setBaseIndexVersionId('');
            } else {
                setBaseIndexVersionId(id);
            }
        },
        [
            isCreateFlow,
            setBaseIndexVersionId,
            setBenchmarkPublicIndexIds,
            setCanUpdateName,
            setCurrentStrategyId,
            setDescription,
            setIsHidden,
            setIsPortfolio,
            setName,
            setHasRebalanceSchedule,
            setSymbol,
            setStrategies,
        ]
    );

    const populateFieldsFromIndexId = useCallback(
        (indexId: string) => {
            setIsPopulatingFieldsFromIndex(true);

            queryIndex(indexId)
                .then(({ index }) => {
                    populateFieldsFromIndex(index);
                    setIsPopulatingFieldsFromIndex(false);
                })
                .catch((error) => {
                    // TODO: handle this error if it's specifically `not found`
                    throw new Error(error);
                });
        },
        [setIsPopulatingFieldsFromIndex, populateFieldsFromIndex]
    );

    // Should this just blow away the localStorage context altogether?
    const resetIndexBuilderContext = useCallback(() => {
        setBaseIndexVersionId('');
        setBenchmarkInstrumentIds([]);
        setBenchmarkMyIndexIds([]);
        setBenchmarkPublicIndexIds(DEFAULT_INDEX_BUILDER_BENCHMARK_INDEX_IDS);
        setBenchmarkThematicIndexIds([]);
        setCanUpdateName(true);
        setDefinedFundamentals(BuildFundamentalInputDefaults());
        setDescription('');
        setName('');
        setOriginatingAnalystThemeRequestId('');
        setSearchValue('');
        setCompanyEnabled(true);
        setCryptoEnabled(false);
        setEtfEnabled(false);
        setIsPortfolio(true);
        setIsHidden(false);
        setSelectedIndustries([]);
        setSelectedSectors([]);
        setSymbol('');
    }, [
        setBaseIndexVersionId,
        setBenchmarkInstrumentIds,
        setBenchmarkMyIndexIds,
        setBenchmarkPublicIndexIds,
        setBenchmarkThematicIndexIds,
        setCanUpdateName,
        setDefinedFundamentals,
        setDescription,
        setName,
        setOriginatingAnalystThemeRequestId,
        setSearchValue,
        setCompanyEnabled,
        setCryptoEnabled,
        setEtfEnabled,
        setIsPortfolio,
        setIsHidden,
        setSelectedIndustries,
        setSelectedSectors,
        setSymbol,
    ]);

    const exportToIndexBuilder = useCallback(
        ({ analysis, requestId, selectedInstrumentResults, themeName }: ExportToIndexBuilderParams) => {
            resetIndexBuilderContext();

            setName(themeName);
            setDescription((analysis || '') + `\n\nThis index was generated with help from Thematic Analyst.`);
            setOriginatingAnalystThemeRequestId(requestId);
            const securityIds = Array.isArray(selectedInstrumentResults) ? selectedInstrumentResults.map(({ instrument: { id } }) => id) : [selectedInstrumentResults.id];

            const strategyId = new Date().getTime();
            setStrategies(() => {
                return [
                    {
                        id: strategyId,
                        name: themeName,
                        securityIds,
                        weightingType: WeightingStrategyType.equal,
                    },
                ];
            });
            window.open('/index-builder/create/backtest', 'blank_');
        },
        [resetIndexBuilderContext, setDescription, setName, setOriginatingAnalystThemeRequestId, setStrategies]
    );
    useEffect(() => {
        // TODO: use a sentinel for `baseIndexVersionId` we can check it to avoid this query path on subsequent loads in the same edit flow
        if (baseIndexVersionId || !builderIndexId || isCreateFlow) {
            setIsPopulatingFieldsFromIndex(false);
            return;
        }

        // TODO: detect if the activeVersion has *changed*, potentially invalidating the edit in progress
        populateFieldsFromIndexId(builderIndexId);
    }, [baseIndexVersionId, builderIndexId, isCreateFlow, populateFieldsFromIndexId]);

    // Save/update draft state when the index state changes
    useEffect(() => {
        if (!builderIndexId || !currentUser) return;

        // TODO: record and use `[base_]version_id`

        const indexState = {
            builderIndexId,
            description,
            name,
            organizationId: currentOrganizationId,
            strategies,
            symbol,
        };
        const indexDraftString = JSON.stringify(indexState);
        if (draftLastState !== indexDraftString) {
            // debounce updates to the draft state
            clearTimeout(createOrUpdateIndexDraftTimer);
            createOrUpdateIndexDraftTimer = setTimeout(
                () =>
                    // createOrUpdateDraftIndexState errors if the user isn't logged in, so suppress errors
                    createOrUpdateDraftIndexState(draftToken, currentOrganizationId, indexState)
                        .then(({ createOrUpdateIndexDraft }) => {
                            if (createOrUpdateIndexDraft.__typename === 'IndexDraft') {
                                setDraftVersionId(createOrUpdateIndexDraft.versionId);
                                setDraftLastState(indexDraftString);
                            }
                            // TODO: handle `errors`
                        })
                        .catch(() => null),
                1000
            );
        }
    }, [currentOrganizationId, currentUser, builderIndexId, description, name, symbol, draftToken, draftLastState, setDraftLastState, setDraftVersionId, strategies]);

    const hasRulesBasedStrategy = strategies.some(isRulesBasedStrategy);

    return (
        <IndexBuilderContext.Provider
            value={{
                benchmarkInstrumentIds,
                benchmarkMyIndexIds,
                benchmarkPublicIndexIds,
                benchmarkThematicIndexIds,
                builderIndexId,
                canUpdateName,
                clearIndexBuilderContext,
                companyEnabled,
                cryptoEnabled,
                currentStrategy,
                currentStrategyId,
                definedFundamentals,
                description,
                etfEnabled,
                exportToIndexBuilder,
                hasRebalanceSchedule,
                hasRulesBasedStrategy,
                isCreateFlow,
                isHidden,
                isPopulatingFieldsFromIndex,
                isPortfolio,
                name,
                originatingAnalystThemeRequestId,
                populateFieldsFromIndexId,
                resetIndexBuilderContext,
                searchValue,
                selectedCountries,
                selectedIndustries,
                selectedRegions,
                selectedSectors,
                selectedSecurities,
                setBenchmarkInstrumentIds,
                setBenchmarkMyIndexIds,
                setBenchmarkPublicIndexIds,
                setBenchmarkThematicIndexIds,
                setCompanyEnabled,
                setCryptoEnabled,
                setCurrentStrategyId,
                setDefinedFundamentals,
                setDescription,
                setEtfEnabled,
                setHasRebalanceSchedule,
                setIsHidden,
                setIsPortfolio,
                setName,
                setOriginatingAnalystThemeRequestId,
                setSearchValue,
                setSelectedCountries,
                setSelectedIndustries,
                setSelectedRegions,
                setSelectedSectors,
                setStrategies,
                setSymbol,
                strategies,
                symbol,
                useNrr,
            }}
        >
            {children}
        </IndexBuilderContext.Provider>
    );
};

const useIndexBuilderContext = () => {
    const context = useContext(IndexBuilderContext);
    if (!context) {
        throw new Error('useIndexBuilderContext must be used within a IndexBuilderContext');
    }
    return context;
};

export { IndexBuilderContextProvider, useIndexBuilderContext };
