import Link from 'next/link';

import cn from '@/lib/cn';

type TOSMessagingProps = {
    clickedString: string;
    className?: string;
};

const TOSMessaging = ({ clickedString, className }: TOSMessagingProps) => {
    return (
        <div className={cn('mb-6 text-sm text-black', className)}>
            {`By clicking "${clickedString}", you agree to our `}
            <Link
                href="/terms-and-conditions"
                target="_blank"
                className="font-brand-md !text-brand-blue-yves"
            >
                Terms
            </Link>{' '}
            and{' '}
            <Link
                href="/privacy-policy"
                target="_blank"
                className="font-brand-md !text-brand-blue-yves"
            >
                Privacy Policy
            </Link>
            .
        </div>
    );
};

export default TOSMessaging;
