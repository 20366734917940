'use client';

import { gql } from 'graphql-request';
import { usePathname, useSearchParams } from 'next/navigation';
import { parseAsString, useQueryState } from 'nuqs';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { coreFiltersFromAnalystFilter, CoreFiltersType } from '@/components/analyst/core-filters-read-only';
import { useGlobalState } from '@/components/global/global-state';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import {
    ANALYSIS_TYPE_QUERY_PARAM_KEY,
    ANALYST_PATH,
    ANALYST_REQUEST_ID_QUERY_PARAM_KEY,
    PROMPT_QUERY_PARAM_KEY,
    RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY,
} from '@/lib/constants/analyst';
import { GQL_CLIENT } from '@/lib/graphql';
import {
    AnalystThemeResultType,
    analystThemeSearch,
    InstrumentMatchResultType,
    ObfuscatedInstrumentMatchResultType,
    refreshAnalystThemeResult,
} from '@/queries/analyst';
import { Maybe, Scalars } from '@/queries/graphql-types';

const POLL_INTERVAL = 5000;

export type PastThemeRequest = {
    id: string;
    createdAt: string;
    userQuery: string;
};

const visibleOnly = (instrumentResults: Array<InstrumentMatchResultType | ObfuscatedInstrumentMatchResultType>) =>
    instrumentResults.filter(
        (result) => result.__typename === 'ThemeInstrumentMatchResult'
    ) as Array<InstrumentMatchResultType>;

interface AnalystProviderProps {
    children: React.ReactNode;
}

interface AnalystContextType {
    analysis: string;
    analysisSubcategories?: Maybe<Scalars['String']['output']>;
    analysisSummary?: Maybe<Scalars['String']['output']>;
    analystError: string;
    analystHasRun: boolean;
    clearPrompt: (e: React.SyntheticEvent) => void;
    coreFilters: CoreFiltersType;
    executeQuery: (fetchDataSearchPhrase: string) => void;
    fieldsOfInterest: Array<string>;
    instrumentResults: Array<InstrumentMatchResultType | ObfuscatedInstrumentMatchResultType>;
    strongMatchResults: Array<InstrumentMatchResultType | ObfuscatedInstrumentMatchResultType>;
    partialMatchResults: Array<InstrumentMatchResultType | ObfuscatedInstrumentMatchResultType>;
    isLoading: boolean;
    isLoadingAnalysis: boolean;
    isLoadingAnalysisSubcategories: boolean;
    isLoadingAnalysisSummary: boolean;
    isLoadingInstruments: boolean;
    isLoadingQuickModuleInstruments: boolean;
    isLoadingThemeName: boolean;
    isStockScreener: boolean;
    loadRequestId: (toLoadRequestId: string | null) => void;
    pastThemeRequests: Array<PastThemeRequest>;
    processingMessage: string;
    progressPercentage: number;
    prompt: string;
    quickModuleInstrumentIds: Array<string>;
    refreshPastThemeRequests: () => void;
    requestId: string;
    resultTheme: string;
    selectedFilters: Array<string>;
    selectedInstrumentIds: Set<string>;
    setSelectedInstrumentIds: ReactStateSetter<Set<string>>;
    showHistorySection: boolean;
    submitError: string;
    themeName: string;
    topMatchResult?: InstrumentMatchResultType;
    updatePrompt: (newPrompt: string) => void;
    updateSelectedFilters: (filterValue: string) => void;
    visibleInstrumentResults: Array<InstrumentMatchResultType>;
}

const AnalystContext = createContext({} as AnalystContextType);

export const AnalystProvider = ({ children }: AnalystProviderProps) => {
    const { categories, eventTypes, trackManualEvent } = usePosthogTracking();
    const { globalState } = useGlobalState();
    const { currentUser } = globalState;
    const [requestId, setRequestId] = useQueryState<string>(
        ANALYST_REQUEST_ID_QUERY_PARAM_KEY,
        parseAsString.withDefault('')
    );
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const promptQueryParam = searchParams?.get(PROMPT_QUERY_PARAM_KEY) ?? '';
    const analysisTypeParam = searchParams?.get(ANALYSIS_TYPE_QUERY_PARAM_KEY) ?? '';
    const defaultSearchPhrase = (!requestId && promptQueryParam) || '';
    const previousDeafultSearchPhrase = usePrevious(defaultSearchPhrase);
    const [prompt, setPrompt] = useState<string>(defaultSearchPhrase);
    const [selectedFilters, setSelectedFilters] = useState<Array<string>>([]);
    const [showHistorySection, setShowHistorySection] = useState(false);
    const [pastThemeRequests, setPastThemeRequests] = useState<Array<PastThemeRequest>>([]);
    const [topMatchResult, setTopMatchResult] = useState<InstrumentMatchResultType>();
    const [rawInstrumentResults, setInstrumentResults] = useState<
        Array<InstrumentMatchResultType | ObfuscatedInstrumentMatchResultType>
    >([]);
    const [submitError, setSubmitError] = useState('');
    const [analysisSubcategories, setAnalysisSubcategories] = useState<Maybe<Scalars['String']['output']>>('');
    const [analysisSummary, setAnalysisSummary] = useState<Maybe<Scalars['String']['output']>>('');
    const resetSubmitError = () => setSubmitError('');
    const updatePrompt = (newPrompt: string) => {
        setPrompt(newPrompt);
        newPrompt && resetSubmitError();
    };
    const updateSelectedFilters = (filterValue: string) => {
        setSelectedFilters((currentSelectedFilters) => {
            if (currentSelectedFilters.includes(filterValue)) {
                return currentSelectedFilters.filter((f) => f !== filterValue);
            }

            return [...currentSelectedFilters, filterValue];
        });
    };
    const instrumentResults = useMemo(() => {
        // put strongest results first
        return [...rawInstrumentResults].sort((a, b) => b.matchScore - a.matchScore);
    }, [rawInstrumentResults]);

    const visibleInstrumentResults = useMemo(() => {
        return visibleOnly(instrumentResults);
    }, [instrumentResults]);

    // Past theme requests/history
    const refreshPastThemeRequests = useCallback(() => {
        const query = gql`
            query CurrentUser($limit: Int!) {
                currentUser {
                    analystThemeRequests(limit: $limit) {
                        id
                        createdAt
                        userQuery
                    }
                }
            }
        `;
        GQL_CLIENT.request(query, { limit: 10 }).then(({ currentUser }) => {
            const analystThemeRequests = currentUser?.analystThemeRequests ?? [];
            setPastThemeRequests(analystThemeRequests);
        });
    }, [setPastThemeRequests]);

    const [analystHasRun, setAnalystHasRun] = useState<boolean>(false);
    const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
    const [isLoadingAnalysisSubcategories, setIsLoadingAnalysisSubcategories] = useState(false);
    const [isLoadingAnalysisSummary, setIsLoadingAnalysisSummary] = useState(false);
    const [isLoadingInstruments, setIsLoadingInstruments] = useState(false);
    const [isLoadingQuickModuleInstruments, setIsLoadingQuickModuleInstruments] = useState(false);
    const [isLoadingThemeName, setIsLoadingThemeName] = useState(false);
    const [isStockScreener, setIsStockScreener] = useState(false);
    const [processingMessage, setProcessingMessage] = useState('');
    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [analystError, setAnalystError] = useState('');
    const [analysis, setAnalysis] = useState('');

    // resultTheme is the theme after parsing off the filters
    const [resultTheme, setResultTheme] = useState<string>('');
    const [themeName, setThemeName] = useState<string>('');
    const [coreFilters, setCoreFilters] = useState<CoreFiltersType>(coreFiltersFromAnalystFilter(null));
    const [fieldsOfInterest, setFieldsOfInterest] = useState<Array<string>>([]);
    const [quickModuleInstrumentIds, setQuickModuleInstrumentIds] = useState<Array<string>>([]);

    // Select strong matches by default
    const [selectedInstrumentIds, setSelectedInstrumentIds] = useState<Set<string>>(
        new Set(
            visibleInstrumentResults.filter(({ isStrongMatch }) => isStrongMatch).map(({ instrument: { id } }) => id)
        )
    );
    const previousAnalystHasRun = useRef<boolean>(analystHasRun);
    const selectedInstrumentIdsRef = useRef<Set<string>>(selectedInstrumentIds);
    useEffect(() => {
        selectedInstrumentIdsRef.current = selectedInstrumentIds;
    }, [selectedInstrumentIds]);
    const instrumentResultsLengthRef = useRef<number>(instrumentResults.length);
    useEffect(() => {
        instrumentResultsLengthRef.current = instrumentResults.length;
    }, [instrumentResults]);

    const [abortControllers, rawSetAbortControllers] = useState<Array<AbortController>>([]);
    const withNewAbortController: () => Promise<AbortController> = useCallback(() => {
        return new Promise((resolve) => {
            const abortController = new AbortController();
            // TODO: can we wait for this to finish setting?
            rawSetAbortControllers((prev) => [...prev, abortController]);
            resolve(abortController);
        });
    }, [rawSetAbortControllers]);
    const abortRequests = useCallback(() => {
        abortControllers.forEach((abortController) => abortController.abort());
        rawSetAbortControllers([]);
    }, [abortControllers, rawSetAbortControllers]);
    const resetAnalystValues = (resetPrompt = true) => {
        setThemeName('');
        setProcessingMessage('');
        setResultTheme('');
        setAnalysis('');
        setAnalysisSubcategories('');
        setAnalysisSummary('');
        setIsStockScreener(false);
        setCoreFilters(coreFiltersFromAnalystFilter(null));
        setFieldsOfInterest([]);
        setQuickModuleInstrumentIds([]);
        setInstrumentResults([]);
        setSelectedInstrumentIds(new Set());
        setProgressPercentage(0);
        resetPrompt && setPrompt('');
    };
    const clearPrompt = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const search = searchParams?.get(ANALYST_REQUEST_ID_QUERY_PARAM_KEY);

        if (search) {
            window.history.replaceState(null, '', ANALYST_PATH);
        }

        resetAnalystValues();
    };
    const startLoading = () => {
        setAnalystHasRun(false);
        setIsLoadingThemeName(true);
        setIsLoadingQuickModuleInstruments(true);
        setIsLoadingAnalysis(true);
        setIsLoadingAnalysisSubcategories(true);
        setIsLoadingAnalysisSummary(true);
        setIsLoadingInstruments(true);
        setAnalystError('');
        setTopMatchResult(undefined);
        setInstrumentResults([]);
        setSelectedInstrumentIds(new Set());
        setAnalysis('');
        setThemeName('');
        setProgressPercentage(0);
        setShowHistorySection(false);
    };

    const loadRequestId = useCallback(
        (toLoadRequestId: string | null) => {
            if (requestId === toLoadRequestId) return;

            // Cancel all inflight requests first
            abortRequests();

            setRequestId(toLoadRequestId);
        },
        [abortRequests, requestId, setRequestId]
    );

    useEffect(() => {
        !!currentUser && refreshPastThemeRequests();
    }, [refreshPastThemeRequests, currentUser]);

    useEffect(() => {
        setPrompt(promptQueryParam);
    }, [promptQueryParam]);

    const handleApiError = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
            setIsLoadingThemeName(false);
            setIsLoadingAnalysis(false);
            setIsLoadingInstruments(false);
            setProcessingMessage('');
            if (error.name !== 'AbortError' && instrumentResultsLengthRef.current < 33) {
                // TODO: do not expose server errors
                setAnalystError(error.message);
            }
            throw error;
        },
        [setIsLoadingThemeName, setIsLoadingAnalysis, setIsLoadingInstruments, setProcessingMessage, setAnalystError]
    );

    const handleAnalystThemeRequest = useCallback(
        (abortController: AbortController, requestId: string) => {
            const pollAnalystThemeResult = async (
                {
                    name,
                    theme,
                    analysis,
                    analysisSubcategories,
                    analysisSummary,
                    fieldsOfInterest,
                    inferredReferenceInstruments,
                    filter,
                    instrumentResults,
                    topMatchResult,
                    isCompleted,
                    isStockScreener,
                    isInstrumentMatchesFullyEvaluated,
                    processingMessage,
                    progressPercentage,
                    userQuery,
                }: AnalystThemeResultType,
                abortController: AbortController
            ) => {
                if (userQuery) {
                    setPrompt(userQuery);
                }
                if (theme) {
                    setResultTheme(theme);
                }
                if (name) {
                    setThemeName(name || '');
                    setIsLoadingThemeName(false);
                }
                if (analysis) {
                    setAnalysis(analysis || '');
                    setIsLoadingAnalysis(false);
                    // TODO: different copy based on isAnalysisCompleted
                }
                if (analysisSubcategories) {
                    setAnalysisSubcategories(analysisSubcategories);
                    setIsLoadingAnalysisSubcategories(false);
                }
                if (analysisSummary) {
                    setAnalysisSummary(analysisSummary);
                    setIsLoadingAnalysisSummary(false);
                }

                if (filter) {
                    const processedCoreFilters = coreFiltersFromAnalystFilter(filter);
                    setCoreFilters(processedCoreFilters);
                }
                if (isStockScreener) {
                    setIsLoadingAnalysis(false);
                }
                setIsStockScreener(isStockScreener);

                if (inferredReferenceInstruments) {
                    setQuickModuleInstrumentIds(
                        inferredReferenceInstruments
                            .map((i) => i.instrumentId)
                            .filter<string>((i): i is string => typeof i === 'string' && !!i)
                    );

                    setIsLoadingQuickModuleInstruments(false);
                }

                if (fieldsOfInterest) {
                    setFieldsOfInterest(fieldsOfInterest);
                }
                setTopMatchResult(topMatchResult ? topMatchResult : undefined);
                if (instrumentResults) {
                    setIsLoadingInstruments(!isInstrumentMatchesFullyEvaluated);
                    setInstrumentResults((prev) => {
                        // use ref since this is in a polling callback
                        const selectedInstrumentIds = selectedInstrumentIdsRef.current;

                        const visibleOnlyPrev = visibleOnly(prev);

                        // Default for strong is selected
                        const allStrongSelected = visibleOnlyPrev
                            .filter(({ isStrongMatch }) => isStrongMatch)
                            .every(({ instrument: { id } }) => selectedInstrumentIds.has(id));

                        // default for partial is unselected
                        const prevPartialResults = visibleOnlyPrev.filter(({ isPartialMatch }) => isPartialMatch);
                        const allPartialSelected =
                            prevPartialResults.length > 0 &&
                            prevPartialResults.every(({ instrument: { id } }) => selectedInstrumentIds.has(id));

                        // Select new matches if existing peer matches are all selected
                        const newSelected = new Set([...selectedInstrumentIds]);
                        visibleOnly(instrumentResults).forEach(
                            ({ isStrongMatch, isPartialMatch, instrument: { id } }) => {
                                if ((isStrongMatch && allStrongSelected) || (isPartialMatch && allPartialSelected)) {
                                    newSelected.add(id);
                                }
                            }
                        );
                        setSelectedInstrumentIds(newSelected);

                        return instrumentResults;
                    });
                }

                setProcessingMessage(processingMessage || '');
                setProgressPercentage(progressPercentage);

                if (isCompleted) {
                    setAnalystHasRun(true);
                    return;
                }

                setTimeout(() => handleAnalystThemeRequest(abortController, requestId), POLL_INTERVAL);
            };

            refreshAnalystThemeResult(requestId, { abortController })
                .then((result) => pollAnalystThemeResult(result, abortController))
                .catch(handleApiError);
        },
        [handleApiError]
    );

    const isLoading = isLoadingAnalysis || isLoadingInstruments || isLoadingThemeName;

    const handleUpdateUrl = useCallback(
        (query: string) => {
            const queryString = searchParams?.toString();
            const paramsCopy = new URLSearchParams(queryString);

            paramsCopy.set(PROMPT_QUERY_PARAM_KEY, encodeURIComponent(query));
            paramsCopy.set(ANALYSIS_TYPE_QUERY_PARAM_KEY, encodeURIComponent(RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY));
            const queryParams = paramsCopy?.toString();

            window.history.replaceState({}, '', `${pathname}?${queryParams}`);
        },
        [pathname, searchParams]
    );

    const executeQuery = useCallback(
        (fetchDataSearchPhrase: string) => {
            handleUpdateUrl(fetchDataSearchPhrase);
            loadRequestId(null);
            resetAnalystValues(false);
            startLoading();
            setProcessingMessage(
                "We're researching this topic for you. This may take a minute, but it's worth the wait"
            );
            withNewAbortController().then((abortController) => {
                analystThemeSearch(fetchDataSearchPhrase, null, { abortController })
                    .then(({ requestId }) => setRequestId(requestId))
                    .catch(handleApiError);
            });
        },
        [handleApiError, handleUpdateUrl, loadRequestId, setRequestId, withNewAbortController]
    );
    const strongMatchResults = useMemo(() => instrumentResults.filter((r) => r.isStrongMatch), [instrumentResults]);
    const partialMatchResults = useMemo(() => instrumentResults.filter((r) => r.isPartialMatch), [instrumentResults]);

    useEffect(() => {
        if (
            pathname === ANALYST_PATH &&
            !isLoadingAnalysis &&
            previousDeafultSearchPhrase !== defaultSearchPhrase &&
            defaultSearchPhrase
        ) {
            setPrompt(defaultSearchPhrase);
            executeQuery(defaultSearchPhrase);
        }
    }, [
        analysisTypeParam,
        defaultSearchPhrase,
        executeQuery,
        isLoadingAnalysis,
        pathname,
        previousDeafultSearchPhrase,
    ]);

    useEffect(() => {
        if (!requestId) return;

        startLoading();
        withNewAbortController().then((abortController) => {
            handleAnalystThemeRequest(abortController, requestId);
        });
    }, [requestId, withNewAbortController, handleAnalystThemeRequest]);

    useEffect(() => {
        const removePromptQueryParam = () => {
            const queryString = searchParams?.toString();
            const paramsCopy = new URLSearchParams(queryString);

            paramsCopy.delete(PROMPT_QUERY_PARAM_KEY);
            const queryParams = paramsCopy?.toString();

            window.history.replaceState({}, '', `${pathname}?${queryParams}`);
        };

        if (requestId) {
            removePromptQueryParam();
        }
    }, [requestId, searchParams, pathname]);

    useEffect(() => {
        if (!previousAnalystHasRun.current && analystHasRun) {
            trackManualEvent({
                eventType: eventTypes.PROMPT_QUERY_COMPLETED,
                trackingProperties: {
                    category: categories.RESEARCH_ANALYSIS,
                    prompt,
                    requestId,
                    themeName,
                },
            });
        }
    }, [
        analystHasRun,
        categories.RESEARCH_ANALYSIS,
        eventTypes.PROMPT_QUERY_COMPLETED,
        previousAnalystHasRun,
        prompt,
        requestId,
        themeName,
        trackManualEvent,
    ]);

    const value: AnalystContextType = {
        analysis,
        analysisSubcategories,
        analysisSummary,
        analystError,
        analystHasRun,
        clearPrompt,
        coreFilters,
        executeQuery,
        fieldsOfInterest,
        instrumentResults,
        isLoading,
        isLoadingAnalysis,
        isLoadingAnalysisSubcategories,
        isLoadingAnalysisSummary,
        isLoadingInstruments,
        isLoadingQuickModuleInstruments,
        isLoadingThemeName,
        isStockScreener,
        loadRequestId,
        partialMatchResults,
        pastThemeRequests,
        processingMessage,
        progressPercentage,
        prompt,
        quickModuleInstrumentIds,
        refreshPastThemeRequests,
        requestId,
        resultTheme,
        selectedFilters,
        selectedInstrumentIds,
        setSelectedInstrumentIds,
        showHistorySection,
        strongMatchResults,
        submitError,
        themeName,
        topMatchResult,
        updatePrompt,
        updateSelectedFilters,
        visibleInstrumentResults,
    };
    return <AnalystContext.Provider value={value}>{children}</AnalystContext.Provider>;
};

export const useAnalyst = () => {
    const context: AnalystContextType = useContext(AnalystContext);
    if (!context) {
        throw new Error('useAnalyst must be used within a AnalystProvider');
    }
    return context;
};
