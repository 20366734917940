import { OptionsType } from '@/components/dom/form-elements';
import { AutoFormatCurrencyAmount, FormatCurrencyAmount, FormatPercentage, RoundToDecimals } from '@/helpers/numbers';
import { FundamentalsType } from '@/types/fundamentals';

export type FundamentalColumn = {
    displayLabel: string;
    csvLabel: string;
    unitLabel?: string;
    unitIsPrefixed?: boolean;
    placeholderExample?: string;
    // TODO: type this
    formatFunc: CallableFunction;
};

type FormattedFundamental = {
    name: string;
    label: string;
    value: string | null;
};

type QueriedFundamentalType = {
    name: string;
} & (
    | {
          __typename: 'InstrumentFundamentalDate';
          dateValue: Optional<string>;
          stringValue?: never;
          decimalValue?: never;
      }
    | {
          __typename: 'InstrumentFundamentalString';
          stringValue: Optional<string>;
          dateValue?: never;
          decimalValue?: never;
      }
    | {
          __typename: 'InstrumentFundamentalDecimal';
          decimalValue: Optional<string>;
          dateValue?: never;
          stringValue?: never;
      }
);

const formatScaledCurrencyAmount = (x: number) => AutoFormatCurrencyAmount(x);
const formatMultiplier = (x: number) => FormatPercentage(x, { suffix: '' });
const formatVolume = (x: number) => RoundToDecimals(0, x);
const formatTwoDecimalPlaces = (x: number) => RoundToDecimals(2, x);
// Alternate: formatTwoSigFigs = (x: number) => parseFloat(x.toPrecision(2))
const identity = (x: string) => x;

const FUNDAMENTALS_COLUMN_MAPPING: { [key: string]: FundamentalColumn } = {
    average_daily_volume_last_month: {
        csvLabel: 'Average Daily Volume (1m)',
        displayLabel: 'Average Daily Volume (1m)',
        formatFunc: formatVolume,
    },
    average_daily_volume_trailing_three_months_usd: {
        csvLabel: 'Average Daily Volume (3m)',
        displayLabel: 'Average Daily Volume (3m)',
        formatFunc: formatVolume,
    },
    beta: {
        csvLabel: 'Beta',
        displayLabel: 'Beta (5Y)',
        formatFunc: formatTwoDecimalPlaces,
    },
    cash_conversion_ratio: {
        csvLabel: 'Cash conversion ratio',
        displayLabel: 'CCR',
        formatFunc: formatTwoDecimalPlaces,
        placeholderExample: '0.75',
        unitIsPrefixed: false,
        unitLabel: '',
    },
    ceo: {
        csvLabel: 'CEO',
        displayLabel: 'CEO',
        formatFunc: identity,
    },
    dividend_per_share: {
        csvLabel: 'Dividend Per Share',
        displayLabel: 'DPS',
        formatFunc: FormatCurrencyAmount,
        placeholderExample: '$2',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    // TODO: deprecate this, replace with `_percent` only
    dividend_yield_daily: {
        csvLabel: 'Dividend Yield',
        displayLabel: 'Yield',
        formatFunc: (x: number) => FormatPercentage(x, { scale: 2 }),
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    dividend_yield_daily_percent: {
        csvLabel: 'Dividend yield',
        displayLabel: 'Dividend yield',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    earnings_per_share: {
        csvLabel: 'Earnings Per Share',
        displayLabel: 'EPS',
        formatFunc: FormatCurrencyAmount,
        placeholderExample: '$2',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    ebitda: {
        csvLabel: 'EBITDA',
        displayLabel: 'EBITDA',
        formatFunc: formatScaledCurrencyAmount,
        placeholderExample: '$500M',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    ebitda_margin: {
        csvLabel: 'EBITDA margin',
        displayLabel: 'EBITDA margin',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    enterprise_value_revenue_ratio: {
        csvLabel: 'EV/Revenue',
        displayLabel: 'EV/Revenue',
        formatFunc: formatMultiplier,
        placeholderExample: '20x',
        unitLabel: 'multiple',
    },
    five_year_compound_annual_revenue_growth_rate: {
        csvLabel: `Revenue growth (5yr)`,
        displayLabel: `Rev growth (5yr)`,
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    float_as_percentage_of_shares_outstanding_usd: {
        csvLabel: 'Float',
        displayLabel: 'Float',
        formatFunc: FormatPercentage,
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    future_assets_upcoming_quarter: {
        csvLabel: 'Next Quarter Assets',
        displayLabel: 'Assets (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_assets_upcoming_year: {
        csvLabel: 'Next Year Assets',
        displayLabel: 'Assets (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_cfps_upcoming_quarter: {
        csvLabel: 'Next Quarter Cash Flow Per Share',
        displayLabel: 'CFPS (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_cfps_upcoming_year: {
        csvLabel: 'Next Year Cash Flow Per Share',
        displayLabel: 'CFPS (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_dps_upcoming_quarter: {
        csvLabel: 'Next Quarter Dividends Per Share',
        displayLabel: 'DPS (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_dps_upcoming_year: {
        csvLabel: 'Next Year Dividends Per Share',
        displayLabel: 'DPS (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_ebitda_upcoming_quarter: {
        csvLabel: 'Next Quarter EBITDA',
        displayLabel: 'EBITDA (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_ebitda_upcoming_year: {
        csvLabel: 'Next Year EBITDA',
        displayLabel: 'EBITDA (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_eps_upcoming_quarter: {
        csvLabel: 'Next Quarter Earnings Per Share',
        displayLabel: 'EPS (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_eps_upcoming_year: {
        csvLabel: 'Next Year Earnings Per Share',
        displayLabel: 'EPS (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_net_inc_upcoming_quarter: {
        csvLabel: 'Next Quarter Net Income',
        displayLabel: 'Net Income (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_net_inc_upcoming_year: {
        csvLabel: 'Next Year Net Income',
        displayLabel: 'Net Income (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_sales_upcoming_quarter: {
        csvLabel: 'Next Quarter Sales',
        displayLabel: 'Sales (Next Quarter)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    future_sales_upcoming_year: {
        csvLabel: 'Next Year Sales',
        displayLabel: 'Sales (Next Year)',
        formatFunc: FormatCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    gross_income_margin: {
        csvLabel: `Gross margin`,
        displayLabel: 'Gross margin',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    industry_name: {
        csvLabel: 'Industry',
        displayLabel: 'Industry',
        formatFunc: identity,
    },
    market_capitalization_usd: {
        csvLabel: `Market cap`,
        displayLabel: 'Market cap',
        formatFunc: formatScaledCurrencyAmount,
        placeholderExample: '$500M',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    net_income: {
        csvLabel: 'Net income',
        displayLabel: 'Net income',
        formatFunc: formatScaledCurrencyAmount,
        placeholderExample: '$500M',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    net_income_margin: {
        csvLabel: 'Net margin',
        displayLabel: 'Net margin',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    net_revenue_retention: {
        csvLabel: 'Net revenue retention',
        displayLabel: 'NRR',
        formatFunc: (x: number) => FormatPercentage(x, { places: 0 }),
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    one_year_annual_revenue_growth_rate: {
        csvLabel: `Revenue growth (1yr)`,
        displayLabel: `Rev growth (1yr)`,
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    one_year_price_performance: {
        csvLabel: 'Price performance (1y)',
        displayLabel: 'Price performance (1y)',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    price_earnings_ratio: {
        csvLabel: 'Price/Earnings',
        displayLabel: 'P/E',
        formatFunc: formatMultiplier,
        placeholderExample: '20x',
        unitLabel: 'multiple',
    },
    price_three_month_drawdown: {
        csvLabel: 'Drawdown (3m)',
        displayLabel: 'Drawdown (3m)',
        formatFunc: formatTwoDecimalPlaces,
        placeholderExample: '5',
    },
    price_three_month_rolling_volatility: {
        csvLabel: 'Rolling volatility (3m)',
        displayLabel: 'Rolling volatility (3m)',
        formatFunc: formatTwoDecimalPlaces,
        placeholderExample: '5',
    },
    price_three_month_sharpe_ratio: {
        csvLabel: 'Sharpe ratio (3m)',
        displayLabel: 'Sharpe ratio (3m)',
        formatFunc: formatTwoDecimalPlaces,
        placeholderExample: '5',
    },
    price_three_month_standard_deviation: {
        csvLabel: 'Standard deviation (3m)',
        displayLabel: 'Standard deviation (3m)',
        formatFunc: formatTwoDecimalPlaces,
        placeholderExample: '5',
    },
    price_to_book_ratio: {
        csvLabel: 'Price/Book',
        displayLabel: 'P/B',
        formatFunc: formatMultiplier,
        placeholderExample: '20x',
        unitLabel: 'multiple',
    },
    price_to_sales_ratio: {
        csvLabel: 'Price/Sales',
        displayLabel: 'P/S',
        formatFunc: formatMultiplier,
        placeholderExample: '20x',
        unitLabel: 'multiple',
    },
    primary_region_country_code: {
        csvLabel: 'Country',
        displayLabel: 'Country',
        formatFunc: identity,
    },
    return_on_assets: {
        csvLabel: 'ROA',
        displayLabel: 'ROA',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    return_on_total_capital: {
        csvLabel: 'ROCE',
        displayLabel: 'ROCE',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    return_on_total_equity: {
        csvLabel: 'ROE',
        displayLabel: 'ROE',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    sales_or_revenue: {
        csvLabel: 'Revenue (USD)',
        displayLabel: 'Revenue',
        formatFunc: formatScaledCurrencyAmount,
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    sector_name: {
        csvLabel: 'Sector',
        displayLabel: 'Sector',
        formatFunc: identity,
    },
    three_year_compound_annual_revenue_growth_rate: {
        csvLabel: `Revenue Growth (3yr)`,
        displayLabel: `Rev Growth (3yr)`,
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    three_year_price_performance: {
        csvLabel: 'Price performance (3y)',
        displayLabel: 'Price performance (3y)',
        formatFunc: FormatPercentage,
        placeholderExample: '10%',
        unitIsPrefixed: false,
        unitLabel: '%',
    },
    total_assets: {
        csvLabel: 'Total Assets',
        displayLabel: 'Total Assets',
        formatFunc: formatScaledCurrencyAmount,
        placeholderExample: '$500M',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    total_enterprise_value: {
        csvLabel: 'Enterprise Value',
        displayLabel: 'EV',
        formatFunc: formatScaledCurrencyAmount,
        placeholderExample: '$500M',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
    total_liabilities: {
        csvLabel: 'Total Liabilities',
        displayLabel: 'Total Liabilities',
        formatFunc: formatScaledCurrencyAmount,
        placeholderExample: '$500M',
        unitIsPrefixed: true,
        unitLabel: '$',
    },
};

export const SUPPORTED_FUNDAMENTAL_FIELDS = Object.keys(FUNDAMENTALS_COLUMN_MAPPING);

export const SUPPORTED_FUNDAMENTAL_FIELDS_AS_OPTIONS: Array<OptionsType> = Object.entries(
    FUNDAMENTALS_COLUMN_MAPPING
).map(([key, value]) => ({
    label: value.csvLabel,
    value: key,
}));

export const GetFundamentalColumn = (columnName: string): FundamentalColumn | undefined => {
    return FUNDAMENTALS_COLUMN_MAPPING[columnName];
};

export const GetRawFundamentalValue = (queriedFundamental: QueriedFundamentalType): number | string | null => {
    const { __typename, dateValue, decimalValue, stringValue } = queriedFundamental;
    switch (__typename) {
        case 'InstrumentFundamentalDecimal':
            return decimalValue ? parseFloat(decimalValue) : null;
        default:
            return dateValue || stringValue || null;
    }
};

export const GetFormattedFundamental = (queriedFundamental: QueriedFundamentalType): FormattedFundamental => {
    const { name } = queriedFundamental;
    const fundamentalColumn = GetFundamentalColumn(name);
    const label = fundamentalColumn?.displayLabel || name;

    const rawValue = GetRawFundamentalValue(queriedFundamental);
    const value = fundamentalColumn && rawValue ? fundamentalColumn.formatFunc(rawValue) : rawValue;

    return { label, name, value };
};

export const GetFormattedFundamentalFromNameAndValue = (
    fundamentalName: string,
    fundamentalValue: string | number | null
): FormattedFundamental => {
    const name = fundamentalName;
    const fundamentalColumn = GetFundamentalColumn(name);
    const label = fundamentalColumn?.displayLabel || name;

    const rawValue = fundamentalValue;
    const value = fundamentalColumn && rawValue ? fundamentalColumn.formatFunc(rawValue) : rawValue;

    return { label, name, value };
};

export const BuildFundamentalInputDefaults = (): FundamentalsType => {
    return {
        EBITDA_MARGIN: {
            GT: '',
            LT: '',
            suffix: '%',
        },
        ENTERPRISE_VALUE_REVENUE_RATIO: {
            GT: '',
            LT: '',
            suffix: '',
        },
        GROSS_INCOME_MARGIN: {
            GT: '',
            LT: '',
            suffix: '%',
        },
        MARKET_CAPITALIZATION_USD: {
            GT: '',
            LT: '',
            gtScale: 'B',
            ltScale: 'B',
            prefix: '$',
        },
        NET_REVENUE_RETENTION: {
            GT: '',
            LT: '',
            suffix: '%',
        },
        ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE: {
            GT: '',
            LT: '',
            suffix: '%',
        },
    };
};
