import React from 'react';

import { NumberInput, Select, SingleSelectedState } from '@/components/dom/form-elements';
import { Heading } from '@/components/dom/text-elements';
import { FundamentalsResultType } from '@/types/index';

export enum FundamentalField {
    MARKET_CAPITALIZATION_USD = 'MARKET_CAPITALIZATION_USD',
    GROSS_INCOME_MARGIN = 'GROSS_INCOME_MARGIN',
    ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE = 'ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE',
    ENTERPRISE_VALUE_REVENUE_RATIO = 'ENTERPRISE_VALUE_REVENUE_RATIO',
    EBITDA_MARGIN = 'EBITDA_MARGIN',
    NET_REVENUE_RETENTION = 'NET_REVENUE_RETENTION',
}

type FundamentalFieldDetailsType = {
    [key in FundamentalField]: {
        shortLabel: string;
        gqlField: keyof FundamentalsResultType;
        type: 'PERCENTAGE' | 'MULTIPLIER' | 'CURRENCY';
    };
};

export const FundamentalFieldDetails: FundamentalFieldDetailsType = {
    [FundamentalField.MARKET_CAPITALIZATION_USD]: {
        gqlField: 'marketCapitalizationUsd',
        shortLabel: 'Mkt Cap',
        type: 'CURRENCY',
    },
    [FundamentalField.GROSS_INCOME_MARGIN]: {
        gqlField: 'grossIncomeMargin',
        shortLabel: 'Gross Margin',
        type: 'PERCENTAGE',
    },
    [FundamentalField.ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE]: {
        gqlField: 'oneYearAnnualRevenueGrowthRate',
        shortLabel: 'Rev Growth',
        type: 'PERCENTAGE',
    },
    [FundamentalField.ENTERPRISE_VALUE_REVENUE_RATIO]: {
        gqlField: 'enterpriseValueRevenueRatio',
        shortLabel: 'Ent. Val/Rev',
        type: 'MULTIPLIER',
    },
    [FundamentalField.EBITDA_MARGIN]: {
        gqlField: 'ebitdaMargin',
        shortLabel: 'EBITDA Mg.',
        type: 'PERCENTAGE',
    },
    [FundamentalField.NET_REVENUE_RETENTION]: {
        gqlField: 'netRevenueRetention',
        shortLabel: 'NRR',
        type: 'PERCENTAGE',
    },
};

export enum FundamentalModifier {
    GT = 'GT',
    LT = 'LT',
    prefix = 'prefix',
    suffix = 'suffix',
    gtScale = 'gtScale',
    ltScale = 'ltScale',
}

export type FundamentalsType = {
    [key in FundamentalField]: {
        [key in FundamentalModifier]?: string | number | undefined;
    };
};

export const MarketCapScaleOptions = [
    { label: 'Million', value: 'M' },
    { label: 'Billion', value: 'B' },
    { label: 'Trillion', value: 'T' },
];

export const BuildFundamentalInputDefaults = (): FundamentalsType => {
    return {
        EBITDA_MARGIN: {
            GT: '',
            LT: '',
            suffix: '%',
        },
        ENTERPRISE_VALUE_REVENUE_RATIO: {
            GT: '',
            LT: '',
            suffix: '',
        },
        GROSS_INCOME_MARGIN: {
            GT: '',
            LT: '',
            suffix: '%',
        },
        MARKET_CAPITALIZATION_USD: {
            GT: '',
            LT: '',
            gtScale: 'B',
            ltScale: 'B',
            prefix: '$',
        },
        NET_REVENUE_RETENTION: {
            GT: '',
            LT: '',
            suffix: '%',
        },
        ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE: {
            GT: '',
            LT: '',
            suffix: '%',
        },
    };
};

type FundamentalInputsType = {
    values: FundamentalsType | undefined;
    onChange: (value: number | string, field: FundamentalField, modifier: FundamentalModifier) => void;
    useNrr?: boolean;
};

export const FundamentalInputs = ({ values, onChange, useNrr }: FundamentalInputsType) => {
    const valuesWithDefaults = { ...BuildFundamentalInputDefaults(), ...(values || {}) };

    const buildPercentageInputSection = (field: FundamentalField, label: string) => {
        return (
            <div className="mb-6">
                <Heading
                    className="mb-2 !font-brand-md"
                    importance={6}
                >
                    {label}
                </Heading>
                <div className="flex">
                    <NumberInput
                        className="shrink w-1/2 text-sm"
                        placeholder="Min (%)"
                        onChange={(event) => onChange(event.currentTarget.value, field, FundamentalModifier.GT)}
                        value={valuesWithDefaults[field].GT as number}
                    />
                    <NumberInput
                        className="shrink w-1/2 ml-4 text-sm"
                        placeholder="Max (%)"
                        onChange={(event) => onChange(event.currentTarget.value, field, FundamentalModifier.LT)}
                        value={valuesWithDefaults[field].LT as number}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col">
            <div className="mb-6">
                <Heading
                    className="mb-2 !font-brand-md"
                    importance={6}
                >
                    Market Capitalization
                </Heading>
                <div>
                    <div className="flex w-full mb-2">
                        <NumberInput
                            placeholder="Min (USD)"
                            className="text-sm w-1/2"
                            onChange={(event) =>
                                onChange(
                                    event.currentTarget.value,
                                    FundamentalField.MARKET_CAPITALIZATION_USD,
                                    FundamentalModifier.GT
                                )
                            }
                            value={valuesWithDefaults.MARKET_CAPITALIZATION_USD.GT as number}
                        />
                        <Select
                            id="scaleSelect"
                            className="ml-4 text-xs normal-case w-1/2"
                            options={MarketCapScaleOptions}
                            defaultValue={MarketCapScaleOptions[1]}
                            minHeight={0}
                            onChange={(data) =>
                                onChange(
                                    (data as SingleSelectedState)?.value as string,
                                    FundamentalField.MARKET_CAPITALIZATION_USD,
                                    FundamentalModifier.gtScale
                                )
                            }
                        />
                    </div>
                    <div className="flex w-full">
                        <NumberInput
                            placeholder="Max (USD)"
                            className="text-sm w-1/2"
                            onChange={(event) =>
                                onChange(
                                    event.currentTarget.value,
                                    FundamentalField.MARKET_CAPITALIZATION_USD,
                                    FundamentalModifier.LT
                                )
                            }
                            value={valuesWithDefaults.MARKET_CAPITALIZATION_USD.LT as number}
                        />
                        <Select
                            id="scaleSelect"
                            className="ml-4 text-xs normal-case w-1/2"
                            options={MarketCapScaleOptions}
                            defaultValue={MarketCapScaleOptions[1]}
                            minHeight={0}
                            onChange={(data) =>
                                onChange(
                                    (data as SingleSelectedState)?.value as string,
                                    FundamentalField.MARKET_CAPITALIZATION_USD,
                                    FundamentalModifier.ltScale
                                )
                            }
                        />
                    </div>
                </div>
            </div>

            {buildPercentageInputSection(FundamentalField.ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE, 'Revenue Growth')}
            {buildPercentageInputSection(FundamentalField.GROSS_INCOME_MARGIN, 'Gross Income Margin')}

            <div className="mb-6">
                <Heading
                    className="mb-2 !font-brand-md"
                    importance={6}
                >
                    Enterprise Value/Revenue
                </Heading>
                <div className="flex">
                    <NumberInput
                        className="shrink w-1/2 text-sm"
                        placeholder="Min Multiplier"
                        onChange={(event) =>
                            onChange(
                                event.currentTarget.value,
                                FundamentalField.ENTERPRISE_VALUE_REVENUE_RATIO,
                                FundamentalModifier.GT
                            )
                        }
                        value={valuesWithDefaults.ENTERPRISE_VALUE_REVENUE_RATIO.GT as number}
                    />
                    <NumberInput
                        className="shrink w-1/2 ml-4 text-sm"
                        placeholder="Max Multiplier"
                        onChange={(event) =>
                            onChange(
                                event.currentTarget.value,
                                FundamentalField.ENTERPRISE_VALUE_REVENUE_RATIO,
                                FundamentalModifier.LT
                            )
                        }
                        value={valuesWithDefaults.ENTERPRISE_VALUE_REVENUE_RATIO.LT as number}
                    />
                </div>
            </div>

            {buildPercentageInputSection(FundamentalField.EBITDA_MARGIN, 'EBITDA Margin')}
            {useNrr && buildPercentageInputSection(FundamentalField.NET_REVENUE_RETENTION, 'Net Dollar Retention')}
        </div>
    );
};
