import cn from '@/lib/cn';

const HighlightedText = ({ children, className }: { children: React.ReactNode; className?: string }) => (
    <span
        className={cn(
            'bg-gradient-to-r from-thematic-purple to-thematic-blue bg-clip-text text-transparent',
            className
        )}
    >
        {children}
    </span>
);

export default HighlightedText;
