import cn from '@/lib/cn';

const baseDotClassName = 'h-3 w-3 bg-analyst-lavender rounded-full animate-bounce';

interface LoadingIconProps {
    className?: string;
    dotClassName?: string;
}

const LoadingIcon = ({ className, dotClassName }: LoadingIconProps) => {
    return (
        <div className={cn('flex gap-2 justify-center items-center', className)}>
            <span className="sr-only">Loading...</span>
            <div className={cn(baseDotClassName, '[animation-delay:-0.3s]', dotClassName)}></div>
            <div className={cn(baseDotClassName, '[animation-delay:-0.15s]', dotClassName)}></div>
            <div className={cn(baseDotClassName, dotClassName)}></div>
        </div>
    );
};

export default LoadingIcon;
