import { useEffect, useState } from 'react';

// TODO: rename this to disambiguate from `useContext`

type StorageContext = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

const getStorageContext = <StorageContext>(context: string): StorageContext => {
    if (typeof window === 'undefined') {
        return {} as StorageContext;
    }
    // getting stored context
    const saved = window.localStorage.getItem(context);
    return saved && saved !== 'undefined' ? JSON.parse(saved) : ({} as StorageContext);
};

const getStorageContextValue = <T>(context: string, key: string, defaultValue: T): T => {
    const { [key]: value } = getStorageContext(context) as StorageContext;
    return value === undefined ? defaultValue : value;
};

const setStorageContextValue = <T>(context: string, key: string, value: T) => {
    const prev: StorageContext = getStorageContext(context);
    localStorage.setItem(context, JSON.stringify({ ...prev, [key]: value }));
};

export const clearContextLocalStorage = (context: string) => {
    if (typeof window === 'undefined') {
        return;
    }

    window.localStorage.removeItem(context);
};

type useContextLocalStorageFn = <T>(key: string, defaultValue: T) => [T, ReactStateSetter<T>];
export const makeUseContextLocalStorage = (context: string): useContextLocalStorageFn => {
    const useContextLocalStorageInternal = <T>(key: string, defaultValue: T): [T, ReactStateSetter<T>] => {
        const [value, setValue] = useState(() => {
            return getStorageContextValue<T>(context, key, defaultValue);
        });

        useEffect(() => {
            setStorageContextValue<T>(context, key, value);
        }, [key, value]);

        return [value, setValue];
    };

    return useContextLocalStorageInternal;
};
