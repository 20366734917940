import { gql } from 'graphql-request';

import { type OrganizationMember } from '@/types/user';

export const CURRENT_USER_FIELDS = gql`
    id
    name
    email
    isThematicAdmin
    location
    about
    avatarUrl
    facebookHandle
    githubHandle
    linkedinUrl
    mediumHandle
    redditHandle
    twitterHandle
    createdAt
    organizations {
        id
        createdAt
        name
        avatarUrl
        canUseCryptoInIndexBuilder
        canCurrentUserUpdateOrganizationProfile
    }
    primaryOrganization {
        id
        createdAt
        name
        avatarUrl
        canUseCryptoInIndexBuilder
        canCurrentUserUpdateOrganizationProfile
        accountTier
        organizationUsers {
            role
            user {
                id
                name
                email
                createdAt
            }
        }
    }
`;

interface UserOrganization {
    id: string;
    name: string;
    avatarUrl: Optional<string>;
    canUseCryptoInIndexBuilder: Optional<boolean>;
    canCurrentUserUpdateOrganizationProfile: boolean;
    accountTier: string;
    organizationUsers: Optional<Array<OrganizationMember>>;
}

export type CurrentUserType = {
    id: string;
    name: string;
    email: string;
    isThematicAdmin: boolean;
    location: Optional<string>;
    about: Optional<string>;
    avatarUrl: Optional<string>;
    facebookHandle: Optional<string>;
    githubHandle: Optional<string>;
    linkedinUrl: Optional<string>;
    mediumHandle: Optional<string>;
    redditHandle: Optional<string>;
    twitterHandle: Optional<string>;
    createdAt: string;
    organizations: Optional<Array<UserOrganization>>;
    primaryOrganization: Optional<UserOrganization>;
};

export const CURRENT_USER_QUERY = gql`
    query {
        currentUser {
            ${CURRENT_USER_FIELDS}
        }
    }
`;
