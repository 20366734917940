import CubeSVG from '@/assets/logos/thematic-cube.svg';
import CubeOutlineSVG from '@/assets/logos/thematic-cube-outline.svg';
import ThematicLogoWithWordmark from '@/assets/logos/thematic-logo-with-wordmark.svg';
import WordmarkSVG from '@/assets/logos/thematic-wordmark.svg';
import cn from '@/lib/cn';

const CubeSizeMap = {
    base: 'h-16 w-16',
    custom: '',
    lg: 'h-24 w-24',
    sm: 'h-8 w-8',
    xl: 'h-32 w-32',
    xs: 'h-4 w-4',
};

type CubeProps = {
    className?: string;
    size?: keyof typeof CubeSizeMap;
    color?: 'white' | 'black' | 'gray' | 'danger' | 'gray-shell' | 'chartreuse' | 'inherit';
    type?: 'solid' | 'outline';
};

export const Cube = ({ className, size = 'base', color = 'black', type = 'solid' }: CubeProps) => {
    const colorStyles = {
        'text-brand-black': color === 'black',
        'text-brand-chartreuse': color === 'chartreuse',
        'text-brand-gray-dark': color === 'gray',
        'text-brand-gray-shell': color === 'gray-shell',
        'text-danger': color === 'danger',
        'text-white': color === 'white',
    };

    let Cube = CubeSVG;
    if (type === 'outline') {
        Cube = CubeOutlineSVG;
    }

    return (
        <div className={cn('inline-block', className, colorStyles, CubeSizeMap[size])}>
            <Cube
                fill="currentColor"
                stroke="currentColor"
                width="100%"
                height="100%"
            />
        </div>
    );
};

const WordmarkSizeMap = {
    base: 'h-16',
    custom: '',
    lg: 'h-24',
    sm: 'h-12',
    xl: 'h-32',
    xs: 'h-8',
    xxs: 'h-4',
};

type WordmarkProps = {
    className?: string;
    size?: keyof typeof WordmarkSizeMap;
    color?: 'white' | 'black' | 'gray';
};

export const Wordmark = ({ className, size = 'base', color = 'black' }: WordmarkProps) => {
    const colorStyles = {
        'text-brand-black': color === 'black',
        'text-brand-gray-dark': color === 'gray',
        'text-white': color === 'white',
    };

    return (
        <div className={cn('inline-block', className, colorStyles, WordmarkSizeMap[size])}>
            <WordmarkSVG
                fill="currentColor"
                stroke="currentColor"
                width="100%"
                height="100%"
            />
        </div>
    );
};

const LogoWithWordmarkSizeMap = {
    base: 'h-12',
    sm: 'h-7',
    xs: 'h-4',
};

export const LogoWithWordmark = ({
    size = 'base',
    className,
    color = 'white',
}: {
    className?: string;
    size?: keyof typeof LogoWithWordmarkSizeMap;
    color?: 'white';
}) => {
    const colorStyles = {
        'text-white': color === 'white',
    };

    return (
        <div className={cn('inline-block', colorStyles, LogoWithWordmarkSizeMap[size], className)}>
            <ThematicLogoWithWordmark
                height="100%"
                fill="currentColor"
            />
        </div>
    );
};
