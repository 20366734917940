import ThematicCube from '@/assets/logos/thematic-cube.svg';
import Arrow from '@/icons/arrow.svg';
import AutoSave from '@/icons/auto-save.svg';
import Book from '@/icons/book.svg';
import Bookmark from '@/icons/bookmark.svg';
import Camera from '@/icons/camera.svg';
import Caret from '@/icons/caret.svg';
import CaretCircle from '@/icons/caret-circle.svg';
import CaretCollapse from '@/icons/caret-collapse.svg';
import CaretDown from '@/icons/caret-down.svg';
import CaretSlim from '@/icons/caret-slim.svg';
import Chart from '@/icons/chart.svg';
import Chat from '@/icons/chat.svg';
import Check from '@/icons/check.svg';
import CheckCircle from '@/icons/check-circle.svg';
import CheckedBox from '@/icons/checked-box.svg';
import CircleFilters from '@/icons/circle-filters.svg';
import CircleHistory from '@/icons/circle-history.svg';
import Clock from '@/icons/clock.svg';
import Close from '@/icons/close.svg';
import CloseX from '@/icons/close-x.svg';
import Copy from '@/icons/copy.svg';
import Cube from '@/icons/cube.svg';
import ScrollCaret from '@/icons/curved-caret.svg';
import Delete from '@/icons/delete.svg';
import Download from '@/icons/download.svg';
import DownloadNew from '@/icons/download-new.svg';
import DraftPage from '@/icons/draft-page.svg';
import DropDownArrow from '@/icons/dropdown-arrow.svg';
import Edit from '@/icons/edit.svg';
import Email from '@/icons/email.svg';
import Exit from '@/icons/exit.svg';
import Expand from '@/icons/expand.svg';
import Eye from '@/icons/eye.svg';
import FBShare from '@/icons/fb-share.svg';
import Gmail from '@/icons/gmail.svg';
import Hamburger from '@/icons/hamburger.svg';
import HamburgerMenu from '@/icons/hamburger-menu.svg';
import History from '@/icons/history.svg';
import IndexDraft from '@/icons/index-draft.svg';
import Infinity from '@/icons/infinity.svg';
import Info from '@/icons/info.svg';
import LightingBolt from '@/icons/lighting-bolt.svg';
import LightningBoltFill from '@/icons/lightning-bolt-fill.svg';
import LinkIcon from '@/icons/link.svg';
import LinkedIn from '@/icons/linkedin.svg';
import Loading from '@/icons/loader.svg';
import Lock from '@/icons/lock.svg';
import MenuBars from '@/icons/menu-bars.svg';
import Minimize from '@/icons/minimize.svg';
import Minus from '@/icons/minus.svg';
import NewArrow from '@/icons/new-arrow.svg';
import OpenExternal from '@/icons/open-external.svg';
import PerformanceArrow from '@/icons/performance-arrow.svg';
import Plus from '@/icons/plus.svg';
import Profile from '@/icons/profile.svg';
import QuestionMarkFill from '@/icons/question-mark-fill.svg';
import QuestionMarkOpen from '@/icons/question-mark-open.svg';
import Resources from '@/icons/resources.svg';
import Search from '@/icons/search.svg';
import SectionExpand from '@/icons/section-expand.svg';
import Settings from '@/icons/settings.svg';
import Share from '@/icons/share.svg';
import ShareArrow from '@/icons/share-arrow.svg';
import ShareArrowNew from '@/icons/share-arrow-new.svg';
import Twitter from '@/icons/twitter.svg';
import UncheckedBox from '@/icons/unchecked-box.svg';
import XDelete from '@/icons/x-delete.svg';
import XTwitter from '@/icons/x-twitter.svg';
import cn from '@/lib/cn';

export const IconMap = {
    arrow: Arrow,
    autoSave: AutoSave,
    book: Book,
    bookmark: Bookmark,
    camera: Camera,
    caret: Caret,
    caretCircle: CaretCircle,
    caretCollapse: CaretCollapse,
    caretDown: CaretDown,
    caretSlim: CaretSlim,
    chart: Chart,
    chat: Chat,
    check: Check,
    checkCircle: CheckCircle,
    checkedBox: CheckedBox,
    circleFilters: CircleFilters,
    circleHistory: CircleHistory,
    clock: Clock,
    close: Close,
    closeX: CloseX,
    copy: Copy,
    cube: Cube,
    curvedCaret: ScrollCaret,
    delete: Delete,
    download: Download,
    downloadNew: DownloadNew,
    draftPage: DraftPage,
    dropDownArrow: DropDownArrow,
    edit: Edit,
    email: Email,
    exit: Exit,
    expand: Expand,
    eye: Eye,
    fbShare: FBShare,
    gmail: Gmail,
    hamburger: Hamburger,
    hamburgerMenu: HamburgerMenu,
    history: History,
    indexDraft: IndexDraft,
    infinity: Infinity,
    info: Info,
    lightingBolt: LightingBolt,
    lightningBoltFill: LightningBoltFill,
    link: LinkIcon,
    linkedIn: LinkedIn,
    loading: Loading,
    lock: Lock,
    menuBars: MenuBars,
    minimize: Minimize,
    minus: Minus,
    newArrow: NewArrow,
    openExternal: OpenExternal,
    performanceArrow: PerformanceArrow,
    plus: Plus,
    profile: Profile,
    questionMarkFill: QuestionMarkFill,
    questionMarkOpen: QuestionMarkOpen,
    resources: Resources,
    search: Search,
    sectionExpand: SectionExpand,
    settings: Settings,
    share: Share,
    shareArrow: ShareArrow,
    shareArrowNew: ShareArrowNew,
    thematicCube: ThematicCube,
    twitter: Twitter,
    uncheckedBox: UncheckedBox,
    xDelete: XDelete,
    xTwitter: XTwitter,
};

const SizeMap = {
    base: 'h-4 w-4',
    custom: '',
    lg: 'h-5 w-5',
    sm: 'h-3.5 w-3.5',
    xl: 'h-6 w-6',
    xs: 'h-3 w-3',
    xxl: 'h-8 w-8',
    xxs: 'h-2 w-2',
    xxxs: 'h-1.5 w-1.5',
};

export const ColorMap = {
    analystBlue: 'text-analyst-blue',
    analystDarkLavender: 'text-analyst-lavender-dark',
    analystLavender: 'text-analyst-lavender',
    analystPurple: 'text-analyst-purple',
    black: 'text-brand-black',
    blueNu: 'text-brand-blue-nu',
    blueYves: 'text-brand-blue-yves',
    chartreuse: 'text-brand-chartreuse',
    danger: 'text-brand-danger',
    gray: 'text-brand-gray-dark',
    inherit: '',
    lightGray: 'text-brand-light-gray',
    navy: 'text-brand-navy',
    teal: 'text-brand-teal',
    thematicBlue: 'text-thematic-blue',
    thematicLightBlue: 'text-thematic-light-blue',
    thematicPurple: 'text-thematic-purple',
    thematicPurpleDarker: 'text-thematic-purple-darker',
    unicorn: 'text-brand-unicorn',
    white: 'text-white',
};
export const HoverColorMap = {
    analystDarkLavender: 'hover:text-analyst-dark-lavender',
    analystLavender: 'hover:text-analyst-lavender',
    analystPurple: 'hover:text-analyst-purple',
    black: 'hover:text-brand-black',
    blueNu: 'hover:text-brand-blue-nu',
    blueYves: 'hover:text-brand-blue-yves',
    chartreuse: 'hover:text-brand-chartreuse',
    danger: 'hover:text-brand-danger',
    gray: 'hover:text-brand-gray-dark',
    inherit: '',
    navy: 'hover:text-brand-navy',
    teal: 'hover:text-brand-teal',
    thematicBlue: 'hover:thematic-purple-darker',
    unicorn: 'hover:text-brand-unicorn',
    white: 'hover:text-white',
};
export const GroupHoverColorMap = {
    analystPurple: 'group-hover:text-analyst-lavender',
    black: 'group-hover:text-brand-black',
    blueNu: 'group-hover:text-brand-blue-nu',
    blueYves: 'group-hover:text-brand-blue-yves',
    chartreuse: 'group-hover:text-brand-chartreuse',
    danger: 'group-hover:text-brand-danger',
    gray: 'group-hover:text-brand-gray-dark',
    inherit: '',
    navy: 'group-hover:text-brand-navy',
    teal: 'group-hover:text-brand-teal',
    thematicBlue: 'group-hover:thematic-purple-darker',
    unicorn: 'group-hover:text-brand-unicorn',
    white: 'group-hover:text-white',
};

export type IconProps = {
    type: keyof typeof IconMap;
    size?: keyof typeof SizeMap;
    className?: string;
    iconClassName?: string;
    color?: keyof typeof ColorMap;
    hoverColor?: keyof typeof HoverColorMap;
    groupHoverColor?: keyof typeof GroupHoverColorMap;
    rotate?: 0 | 90 | 180 | -90;
    fill?: string;
    stroke?: string;
};

const Icon = ({
    type,
    size = 'base',
    className,
    iconClassName,
    color = 'black',
    hoverColor,
    groupHoverColor,
    rotate,
    fill = 'currentColor',
    stroke = 'currentColor',
}: IconProps) => {
    const colorStyle = ColorMap[color];
    const hoverColorStyle = hoverColor && HoverColorMap[hoverColor];
    const groupHoverColorStyle = groupHoverColor && GroupHoverColorMap[groupHoverColor];
    const rotateStyles = {
        '-rotate-90': rotate === -90,
        'rotate-180': rotate === 180,
        'rotate-90': rotate === 90,
    };
    const Icon = IconMap[type];

    return (
        <span
            className={cn(
                'inline-block transition-colors duration-200 ease-in-out',
                colorStyle,
                SizeMap[size],
                hoverColorStyle,
                groupHoverColorStyle,
                className
            )}
        >
            <Icon
                className={cn(rotateStyles, '!overflow-visible w-full', iconClassName)}
                {...{ fill, stroke }}
            />
        </span>
    );
};

export default Icon;
