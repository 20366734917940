import { gql } from 'graphql-request';
import { FormEvent, useState } from 'react';

import { FieldError, Label, SubmitButton, TextInput } from '@/components/dom/form-elements';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { GQL_CLIENT } from '@/lib/graphql';
import * as gtag from '@/lib/gtag';
import { BaseTrackingProperties } from '@/types/tracking';

const LOGIN_QUERY = gql`
    mutation CreateLoginAttempt($email: String!) {
        createLoginAttempt(input: { email: $email }) {
            __typename
            ... on SendLoginAttemptSuccess {
                loginAttemptId
            }
            ... on Error {
                message
            }
        }
    }
`;

interface LoginFormProps {
    initialEmail?: string;
    className?: string;
    onSuccess?: (loginAttemptId: string, email: string) => void;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
}

export const LoginForm = ({
    initialEmail = '',
    className,
    onSuccess,
    trackingProperties = {} as BaseTrackingProperties,
}: LoginFormProps) => {
    const { categories, components, eventTypes, trackManualEvent } = usePosthogTracking();
    const [email, setEmail] = useState(initialEmail);
    const [emailError, setEmailError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError('');
    };
    const submitLogInForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = {
            email,
        };

        trackManualEvent({
            eventType: eventTypes.LOGIN_FORM_SUBMIT,
            trackingProperties: {
                ...trackingProperties,
                additionalTrackingProperties: { email },
                category: categories.FORM_SUBMISSION,
                component: components.LOGIN_FORM,
            },
        });

        const data: {
            createLoginAttempt:
                | {
                      __typename: 'SendLoginAttemptSuccess';
                      loginAttemptId: Optional<string>;
                  }
                | {
                      __typename: 'Error';

                      message: string;
                  };
        } = await GQL_CLIENT.request(LOGIN_QUERY, variables);
        const result = data.createLoginAttempt;
        const success = result.__typename === 'SendLoginAttemptSuccess';

        if (success) {
            onSuccess?.(result.loginAttemptId || '', email);
            trackManualEvent({
                eventType: eventTypes.LOGIN_SUCCESS,
                trackingProperties: { ...trackingProperties, component: components.LOGIN_FORM },
            });
        } else {
            setEmailError(result.message);
            trackManualEvent({
                eventType: eventTypes.LOGIN_ERROR,
                trackingProperties: {
                    ...trackingProperties,
                    additionalTrackingProperties: {
                        error: {
                            message: result.message,
                            type: 'email',
                        },
                    },
                    component: components.SIGNUP_FORM,
                },
            });
        }
        setIsSubmitting(false);

        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Log In',
            label: 'Submit',
        });
    };

    return (
        <form
            onSubmit={submitLogInForm}
            noValidate
            className={cn(className)}
        >
            <div className="mb-6">
                <Label
                    text="Email"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        className="w-full"
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={email}
                        onChange={onEmailInput}
                        required={true}
                        spellCheck={false}
                        placeholder="someonecool@yourorganization.com"
                        autoFocus={true}
                    />
                </Label>
                <FieldError message={emailError} />
            </div>
            <SubmitButton
                isDisabled={isSubmitting}
                value="Email me a one-time passcode"
                roundedCorners="full"
                className="font-brand-md mx-0 w-full"
                color="primary-gradient"
            />
        </form>
    );
};
