export const RoundToDecimals = (places: number, value: number): string => {
    const rounded = +(Math.round(Number(value + 'e+' + places)) + 'e-' + places);
    // TODO(joe): create way to get the customers local so we can format numbers properly.
    const locale = 'en-US';
    return rounded.toLocaleString(locale, {
        maximumFractionDigits: places,
        minimumFractionDigits: places,
    });
};

export const CalcPercentDiff = (start: number, end: number): number => {
    // if start and end are both negative, we need the denominator to still be positive to get the sign right
    return ((end - start) / Math.abs(start)) * 100;
};

export const FormatCurrencyAmount = (
    value: number,
    {
        places = 2,
        scale = 0,
        prefix = '$',
        suffix = '',
    }: {
        places?: number;
        scale?: number;
        prefix?: string;
        suffix?: string;
    } = {}
): string => {
    const sign = Math.sign(value) === -1 ? '-' : '';
    const roundedValue = RoundToDecimals(places, Math.abs(value) * 10 ** scale);

    return `${sign}${prefix}${roundedValue}${suffix}`;
};

export const FormatPercentage = (
    value: number,
    {
        places = 2,
        scale = 0,
        prefix = '',
        suffix = '%',
        describe_truncated = false,
    }: {
        places?: number;
        scale?: number;
        prefix?: string;
        suffix?: string;
        describe_truncated?: boolean;
    } = {}
): string => {
    const sign = Math.sign(value) === -1 ? '-' : '';
    let roundedValue = RoundToDecimals(places, Math.abs(value) * 10 ** scale);

    // use describe_truncated with care
    // it only understands if the value is positive it won't work if the value is negative
    // TODO(joe): figure out a better way to display small numbers that round to zero
    if (describe_truncated && value > 0 && parseFloat(roundedValue) === 0) {
        prefix = '<';
        roundedValue = `0.${'0'.repeat(places - 1)}1`;
    }

    return `${sign}${prefix}${roundedValue}${suffix}`;
};

export const ParseGqlDecimalToNumber = (
    value?: string | null,
    {
        required = false,
    }: {
        required?: boolean;
    } = {}
): number | null => {
    if (!value) {
        if (required) {
            throw new Error('Unexpected null decimal');
        } else {
            return null;
        }
    }
    const n = Number(value);
    if (isNaN(n)) {
        throw new Error('Unable to parse ' + value);
    }

    return n;
};

export const Sum = (values: Array<number>): number => {
    return values.reduce((acc: number, weight: number) => acc + weight, 0);
};

export const AutoFormatCurrencyAmount = (
    value: number,
    {
        places = 2,
    }: {
        places?: number;
    } = {}
): string => {
    const absoluteValue = Math.abs(value);
    let suffix = 'M';
    let scale = -6;
    if (absoluteValue >= 1000000000000) {
        suffix = 'T';
        scale = -12;
    } else if (absoluteValue >= 1000000000) {
        suffix = 'B';
        scale = -9;
    }

    return FormatCurrencyAmount(value, {
        places,
        scale,
        suffix,
    });
};

export function ToFixedNumber({ num, digits, base }: { num: number; digits: number; base?: number }): number {
    const pow = Math.pow(base ?? 10, digits);

    return Math.round(num * pow) / pow;
}

export function GetFileSizeInMB(sizeInBytes: number): number {
    return ToFixedNumber({ digits: 2, num: sizeInBytes / (1024 * 1024) });
}

export const multiplierFromScale = (scale: string | number | undefined) => {
    if (scale === 'M') {
        return 1000000;
    } else if (scale === 'B') {
        return 1000000000;
    } else if (scale === 'T') {
        return 1000000000000;
    }
    return 1;
};
