import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

export const useNextCookieState = <T>(cookieName: string, defaultValue: T): [T, ReactStateSetter<T>] => {
    const [value, setValue] = useState<T>(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cookieValue: any = getCookie(cookieName);
        return cookieValue === undefined ? defaultValue : cookieValue;
    });

    useEffect(() => {
        if (value) {
            setCookie(cookieName, value);
        } else {
            deleteCookie(cookieName);
        }
    }, [cookieName, value]);

    return [value, setValue];
};
