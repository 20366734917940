import { MutableRefObject, useEffect } from 'react';

export const ClickedOutSide = (ref: MutableRefObject<HTMLElement | null>, callback: CallableFunction) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current) {
                const current = ref.current as HTMLElement;
                if (!current.contains(event.target as Node)) {
                    callback();
                }
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};
