export const START_PAGE_TAB_VIEW_QUERY_PARAM = 'tv';
export const START_PAGE_STOCK_SCREENER_TAB_VIEW_QUERY_PARAM = 'stockScreener';
export const START_PAGE_COMPANY_SEARCH_TAB_VIEW_QUERY_PARAM = 'companySearch';
export const MAIN_NAV_LINKS = [
    {
        iconType: 'book',
        label: 'Research',
        subMenu: [
            { href: '/app/start', label: 'Research a topic', tabIndex: 3 },
            {
                href: `/app/start?${START_PAGE_TAB_VIEW_QUERY_PARAM}=${START_PAGE_STOCK_SCREENER_TAB_VIEW_QUERY_PARAM}`,
                label: 'Stock screener',
                tabIndex: 4,
            },
            {
                href: `/app/start?${START_PAGE_TAB_VIEW_QUERY_PARAM}=${START_PAGE_COMPANY_SEARCH_TAB_VIEW_QUERY_PARAM}`,
                label: 'Find companies',
                tabIndex: 5,
            },
        ],
        tabIndex: 2,
    },
    {
        iconType: 'cube',
        label: 'Build',
        subMenu: [
            { href: '/index-builder/create', label: 'Search for securities', tabIndex: 7 },
            {
                href: '/index-builder/create',
                label: 'Load an existing portfolio',
                tabIndex: 8,
            },
            { href: '/index-builder/create/backtest', label: 'Backtest', tabIndex: 9 },
        ],
        tabIndex: 6,
    },
    {
        label: 'Capabilities',
        subMenu: [
            { href: '/product', label: 'Thematic overview', tabIndex: 11 },
            {
                href: '/product/asset-management-product-development',
                label: 'Develop asset-backed products',
                tabIndex: 12,
            },
            { href: '/product/evaluation', label: 'Company evaluations', tabIndex: 13 },
            { href: '/backtesting-and-benchmarking', label: 'Backtesting and benchmarking', tabIndex: 14 },
            { href: '/thematic-benchmarks', label: 'Thematic branded indices', tabIndex: 15 },
        ],
        tabIndex: 10,
    },
    {
        href: '/about-us',
        label: 'About us',
        tabIndex: 16,
    },
    {
        href: '/blog',
        label: 'Blog',
        tabIndex: 17,
    },
];
