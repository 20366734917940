import React, { forwardRef, HTMLInputTypeAttribute, ReactNode, useRef } from 'react';
import ReactSelect, {
    ActionMeta,
    ClearIndicatorProps,
    components,
    MultiValueRemoveProps,
    OptionProps,
} from 'react-select';

import styles from '@/components/dom/form-element-styles.module.css';
import Button, { ButtonCommonProps } from '@/components/global/button';
import Icon from '@/components/global/icon';
import cn from '@/lib/cn';

type TextInputProps = {
    type?: HTMLInputTypeAttribute;
    className?: string;
    error?: string;
    errorType?: 'base' | 'simple';
    fieldClassName?: string;
    isDisabled?: boolean;
    hideNote?: boolean;
    maxLength?: number;
    name?: string;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onKeyDown?: React.KeyboardEventHandler<Element>;
    placeholder?: string;
    required?: boolean;
    spellCheck?: boolean;
    autoFocus?: boolean;
    testId?: string;
    value: string;
    isClearable?: boolean;
    onClear?: () => void;
};

const defaultInputBoxStyles =
    'font-brand-md py-1 px-2 sm:px-4 border border-brand-gray-med rounded-none w-full placeholder-brand-gray-dark placeholder-font-brand-md focus:ring-transparent focus-visible:ring-transparent focus-visible:outline-none focus:border-brand-unicorn focus:bg-white';

const defaultInputBoxHoverStyles = 'hover:border-brand-unicorn';

type InputBaseProps = {
    children?: ReactNode;
    className?: string;
    maxLength?: number;
    currentLength?: number;
    error?: string;
    errorType?: 'base' | 'simple';
    hideNote?: boolean;
};

export const InputBase = ({
    children,
    className,
    maxLength,
    currentLength,
    error,
    errorType,
    hideNote = false,
}: InputBaseProps) => {
    return (
        <div className={className}>
            {children}
            {((!hideNote && maxLength) || error) && (
                <div className="flex w-full mt-1">
                    {error && (
                        <span
                            className={cn(
                                'text-sm self-start py-1 px-2 sm:px-4 bg-brand-danger-light text-brand-danger',
                                { 'bg-transparent': errorType === 'simple' }
                            )}
                        >
                            {error}
                        </span>
                    )}
                    {!hideNote && maxLength && (
                        <span className="font-brand-md text-sm ml-auto self-end">
                            {currentLength}/{maxLength} character max
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export const TextInput = ({
    className,
    error,
    errorType = 'base',
    fieldClassName,
    isDisabled = false,
    maxLength,
    name,
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
    placeholder,
    required = false,
    spellCheck = true,
    testId,
    value,
    autoFocus,
    isClearable = false,
    onClear,
    type = 'text',
}: TextInputProps) => {
    return (
        <InputBase
            className={cn('relative', className)}
            error={error}
            errorType={errorType}
            currentLength={value ? value.length : 0}
            maxLength={maxLength}
        >
            <input
                className={cn(
                    defaultInputBoxStyles,
                    !isDisabled && defaultInputBoxHoverStyles,
                    'form-input',
                    fieldClassName,
                    {
                        'border-brand-danger': error,
                        'text-brand-gray-dark': isDisabled,
                    }
                )}
                type={type}
                value={value}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                disabled={isDisabled}
                placeholder={placeholder}
                name={name}
                required={required}
                spellCheck={spellCheck}
                maxLength={maxLength}
                data-testid={testId}
                autoFocus={autoFocus}
            />
            {isClearable && onClear && !!value && (
                <Button
                    // TODO: TRACKING
                    type="action"
                    onClick={onClear}
                    color="transparent"
                    className="hover:bg-brand-blue-light rounded-full absolute top-1/2 right-2 -translate-y-1/2 p-3 flex items-center justify-center cursor-pointer"
                >
                    <Icon
                        type="closeX"
                        color="thematicPurple"
                        size="xs"
                    />
                </Button>
            )}
        </InputBase>
    );
};

type NumberInputProps = {
    value: string | number | undefined;
    className?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    isDisabled?: boolean;
    placeholder?: string;
    name?: string;
    min?: number;
    max?: number;
    step?: number;
    error?: string;
    errorType?: 'base' | 'simple';
    fieldClassName?: string;
    testId?: string;
};

export const NumberInput = ({
    value,
    className,
    placeholder,
    name,
    min,
    max,
    step,
    isDisabled = false,
    onFocus,
    onChange,
    onBlur,
    error,
    errorType,
    fieldClassName,
    testId,
}: NumberInputProps) => {
    return (
        <InputBase
            className={className}
            error={error}
            errorType={errorType}
        >
            <input
                className={cn(defaultInputBoxStyles, !isDisabled && defaultInputBoxHoverStyles, fieldClassName, {
                    'border-brand-danger': error,
                    'text-brand-gray-dark': isDisabled,
                })}
                type="number"
                value={!isNaN(value as number) ? value : ''}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                placeholder={placeholder}
                name={name}
                min={min}
                max={max}
                step={step}
                data-testid={testId}
            />
        </InputBase>
    );
};

export const EmailInput = ({
    value,
    className,
    placeholder,
    name,
    spellCheck = true,
    isDisabled = false,
    onFocus,
    onChange,
    onBlur,
    onKeyDown,
    required = false,
    error,
    errorType = 'base',
    fieldClassName,
    testId,
}: TextInputProps) => {
    return (
        <InputBase
            className={className}
            error={error}
            errorType={errorType}
        >
            <input
                className={cn(defaultInputBoxStyles, !isDisabled && defaultInputBoxHoverStyles, fieldClassName, {
                    'border-brand-danger': error,
                    'text-brand-gray-light': isDisabled,
                })}
                type="email"
                value={value}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                disabled={isDisabled}
                placeholder={placeholder}
                name={name}
                required={required}
                spellCheck={spellCheck}
                data-testid={testId}
            />
        </InputBase>
    );
};

export const TextArea = forwardRef(function TextAreaComponent(
    {
        value,
        className,
        placeholder,
        name,
        spellCheck = true,
        isDisabled = false,
        hideNote = false,
        onFocus,
        onChange,
        onBlur,
        onKeyDown,
        required = false,
        maxLength,
        error,
        errorType = 'base',
        fieldClassName,
        testId,
        rows = 5,
        cols,
    }: TextInputProps & { rows?: number; cols?: number },
    ref: React.Ref<HTMLTextAreaElement>
) {
    return (
        <InputBase
            className={className}
            hideNote={hideNote}
            error={error}
            errorType={errorType}
            maxLength={maxLength}
            currentLength={value ? value.length : 0}
        >
            <textarea
                ref={ref}
                className={cn(
                    defaultInputBoxStyles,
                    !isDisabled && defaultInputBoxHoverStyles,
                    'text-sm !p-4',
                    {
                        'border-brand-danger': error,
                        'text-brand-gray-dark': isDisabled,
                    },
                    fieldClassName
                )}
                value={value}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                spellCheck={spellCheck}
                disabled={isDisabled}
                name={name}
                placeholder={placeholder}
                required={required}
                rows={rows}
                cols={cols}
                maxLength={maxLength}
                data-testid={testId}
            />
        </InputBase>
    );
});

type LabelProps = {
    children?: ReactNode;
    text: string | JSX.Element;
    className?: string;
    labelCopyClassName?: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
    allowWrap?: boolean;
};

export const Label = ({
    text,
    position = 'top',
    children,
    className,
    allowWrap = false,
    labelCopyClassName,
}: LabelProps) => {
    const positionStyles = {
        'flex-col': position === 'top',
        'flex-col-reverse': position === 'bottom',
        'flex-row items-center justify-start': position === 'left',
        'flex-row-reverse items-center justify-end': position === 'right',
    };

    const labelPositionStyles = {
        'mb-1': position === 'top',
        'ml-2': position === 'right',
        'mr-2': position === 'left',
        'mt-1': position === 'bottom',
    };

    return (
        <label className={cn(cn('flex font-brand-md text-sm w-full cursor-pointer', className), positionStyles)}>
            <span
                className={cn('flex-shrink-0', { 'flex-shrink': allowWrap }, labelPositionStyles, labelCopyClassName)}
            >
                {text}
            </span>
            {children}
        </label>
    );
};

type RadioInputProps = {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    options: Array<{
        value: string;
        label: string;
    }>;
    selected: string;
    className?: string;
};

export const RadioInputGroup = ({ options, selected, className, onChange }: RadioInputProps) => {
    const defaultStyles = '';
    const inputStyles = 'opacity-0';
    const checkedStyles = styles['radioButtonChecked'];

    return (
        <div className={cn(defaultStyles, className)}>
            {' '}
            {options.map((item, index) => {
                return (
                    <Label
                        key={`input-radio-button--${index}`}
                        className={cn('indent-7', styles['radioButton'], {
                            [checkedStyles]: selected === item.value,
                        })}
                        text={item.label}
                        position="left"
                    >
                        <input
                            className={cn(inputStyles)}
                            type="radio"
                            value={item.value}
                            checked={selected === item.value}
                            onChange={onChange}
                        />
                    </Label>
                );
            })}{' '}
        </div>
    );
};

type SubmitButtonProps = {
    value: string;
    isDisabled?: boolean;
    position?: 'left' | 'right' | 'center';
    fullWidth?: boolean;
    className?: string;
    color?: ButtonCommonProps['color'];
    roundedCorners?: ButtonCommonProps['roundedCorners'];
};

export const SubmitButton = ({
    value,
    isDisabled = false,
    position = 'center',
    fullWidth = false,
    color = 'primary',
    roundedCorners,
    className,
}: SubmitButtonProps) => {
    return (
        <Button
            // TODO: TRACKING
            className={cn(
                cn('font-brand-bold', {
                    'ml-0': position === 'left',
                    'mr-0': position === 'right',
                    'mx-auto': position === 'center',
                    'w-full': fullWidth === true,
                }),
                className
            )}
            shadow={false}
            type="submit"
            isDisabled={isDisabled}
            value={value}
            color={color}
            roundedCorners={roundedCorners}
        />
    );
};

export type OptionsType = {
    value: string | number | boolean | null;
    label: string;
    description?: string;
    isDisabled?: boolean;
};

export type GroupedOptionsType = {
    label: string;
    options: Array<OptionsType>;
};

export type OptionsState = OptionsType | ReadonlyArray<OptionsType> | ReadonlyArray<GroupedOptionsType> | null;
export type SingleSelectedState = OptionsType | null;
export type MultiSelectedState = Array<OptionsType> | null;

export type OnChange = (value: OptionsState, actionMeta: ActionMeta<OptionsType | null>) => void;
type isOptionDisabled = (option: OptionsType) => boolean;

export type SelectProps = {
    borderRadius?: number | string;
    className?: string;
    defaultValue?: OptionsType;
    formatOptionLabel?: (o: OptionsType) => ReactNode;
    isClearable?: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    isOptionDisabled?: isOptionDisabled;
    isSearchable?: boolean;
    name?: string;
    onChange: OnChange;
    options: Array<OptionsType> | Array<GroupedOptionsType>;
    placeholder?: string;
    value?: OptionsType | Array<OptionsType> | null;
    id?: string;
    menuPlacement?: 'auto' | 'bottom' | 'top';
    minHeight?: number;
    maxMenuHeight?: number;
    menuShouldScrollIntoView?: boolean;
    closeMenuOnSelect?: boolean;
    onBlur?: () => void;
};

const SelectOption: React.FC<OptionProps<OptionsType, boolean, GroupedOptionsType>> = ({
    innerProps,
    isDisabled,
    children,
    isSelected,
    isFocused,
}) => {
    return (
        <div
            className={cn('flex items-center justify-between py-2 px-4 text-analyst-black', {
                'bg-brand-gray-med text-white relative cursor-not-allowed': isDisabled,
                'hover:bg-brand-gray-light cursor-pointer': !isDisabled,
                'text-thematic-purple bg-brand-gray': (isSelected || isFocused) && !isDisabled,
            })}
            {...innerProps}
        >
            {children}
        </div>
    );
};

export const Select = ({
    borderRadius = 0,
    className,
    defaultValue,
    formatOptionLabel,
    isClearable = false,
    isDisabled = false,
    isMulti = false,
    isOptionDisabled,
    isSearchable = false,
    name,
    onChange,
    options,
    placeholder,
    value,
    id,
    menuPlacement = 'bottom',
    minHeight,
    maxMenuHeight,
    menuShouldScrollIntoView,
    closeMenuOnSelect = true,
    onBlur,
}: SelectProps) => {
    const formatGroupLabel = (data: GroupedOptionsType) => {
        return (
            <div className="flex justify-between">
                <span>{data.label}</span>
                <span className="bg-brand-gray-light rounded-full px-2">{data.options.length}</span>
            </div>
        );
    };

    const getComponents = () => {
        return {
            ClearIndicator: (props: ClearIndicatorProps<OptionsType, boolean, GroupedOptionsType>) => (
                <components.ClearIndicator {...props}>
                    <Icon
                        type="close"
                        size="sm"
                        color="gray"
                        className="mt-1 hover:text-brand-danger cursor-pointer"
                    />
                </components.ClearIndicator>
            ),
            DropdownIndicator: () => (
                <Icon
                    type="dropDownArrow"
                    size="xs"
                    color={isDisabled ? 'gray' : 'blueYves'}
                    className="mx-2 mt-2 group-hover:text-brand-navy"
                />
            ),
            MultiValueRemove: (props: MultiValueRemoveProps<OptionsType, boolean, GroupedOptionsType>) => (
                <components.MultiValueRemove {...props}>
                    <Icon
                        type="close"
                        size="sm"
                        color="navy"
                        className="mr-1 ml-2 mt-1 hover:text-brand-danger w-2 cursor-pointer"
                    />
                </components.MultiValueRemove>
            ),
            Option: SelectOption,
        };
    };

    return (
        <ReactSelect
            className={cn('group !font-brand-md !text-base focus:ring-transparent', className)}
            menuPlacement={menuPlacement}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            onChange={onChange}
            isDisabled={options.length === 0 || isDisabled}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            value={value}
            isOptionDisabled={isOptionDisabled}
            isSearchable={isSearchable}
            defaultValue={defaultValue}
            name={name}
            isClearable={isClearable}
            id={id}
            instanceId={id}
            maxMenuHeight={maxMenuHeight}
            menuShouldScrollIntoView={menuShouldScrollIntoView}
            closeMenuOnSelect={closeMenuOnSelect}
            onBlur={onBlur}
            // @ts-expect-error: `theme` expects a numeric `borderRadius`, but often, we want to specify in `px`
            theme={(theme) => ({
                ...theme,
                borderRadius,
            })}
            styles={{
                control: (base) => ({
                    ...base,
                    boxShadow: '0 !important',
                    cursor: 'pointer',
                    ...(minHeight !== undefined ? { minHeight } : {}),
                }),
                indicatorSeparator: (base) => ({
                    ...base,
                    backgroundColor: '#F2F2F2', // brand-gray
                }),
                multiValue: (base) => ({
                    ...base,
                    background: '#F2F4FF', // brand-navy-light
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    color: '#001497', // brand-navy
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    background: '#F2F4FF !important', // brand-navy-light
                }),
                option: (base, { isFocused, isSelected }) => ({
                    ...base,
                    // brand-navy or brand-black
                    backgroundColor: isFocused || isSelected ? '#F2F2F2' : undefined,
                    // brand-gray or nothing
                    borderBottom: '1px solid #F2F2F2',
                    color: isFocused || isSelected ? '#001497' : '#050505',
                    cursor: 'pointer',
                }),
                singleValue: (base) => ({
                    ...base,
                    color: isDisabled ? '#949494' : '#001497', // enabled: brand-navy, disabled: brand-gray-dark
                }),
            }}
            components={getComponents()}
        />
    );
};

export type CheckboxProps = {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
    name?: string;
    isChecked: boolean;
    isDisabled?: boolean;
    className?: string;
};

export const Checkbox = ({
    name,
    className,
    isChecked = false,
    isDisabled = false,
    onChange,
    onClick,
}: CheckboxProps) => {
    return (
        <input
            type="checkbox"
            checked={isChecked}
            name={name}
            onChange={onChange}
            onClick={onClick}
            disabled={isDisabled}
            className={cn(
                'form-checkbox w-4 h-4 rounded-none border text-brand-blue-yves',
                'hover:border-brand-unicorn focus:ring-transparent focus:border-brand-unicorn focus:border-2 cursor-pointer',
                {
                    'text-brand-gray-dark': isDisabled,
                },
                className
            )}
        />
    );
};

type ImageUploadType = {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    className?: string;
    id?: string;
};

export const ImageUpload = ({ onChange, className, id }: ImageUploadType) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <input
            ref={inputRef}
            id={id}
            className={cn(className, 'w-60')}
            type="file"
            accept=".jpeg,.jpg,.png"
            onChange={onChange}
            onClick={() => {
                // This allows onChange to fire when the same file is
                // selected
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
            }}
        />
    );
};

type FieldErrorProps = {
    className?: string;
    message: string;
};

export const FieldError = ({ className, message }: FieldErrorProps) => {
    const defaultStyles = 'mt-1 text-sm text-brand-danger';

    if (message) {
        return <div className={cn(className, defaultStyles)}>{message}</div>;
    }

    return null;
};

export const FormSection = ({ children }: { children: React.ReactNode }) => {
    return <section className="mb-10">{children}</section>;
};

export const LabelFieldWrapper = ({
    children,
    className,
    onClick,
}: {
    children: React.ReactNode;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
    return (
        <div
            className={cn('mb-8 last-of-type:mb-0', className)}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
