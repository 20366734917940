export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gtag: any;
    }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
    if (window.gtag) {
        window.gtag('config', GA_TRACKING_ID, {
            page_path: url,
        });
    }
};

// https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
export const setUser = (userId: string) => {
    if (window.gtag) {
        window.gtag('config', GA_TRACKING_ID, {
            user_id: userId,
        });
    }
};

type EventProps = {
    action: string;
    category: string;
    label?: string;
    value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: EventProps) => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value,
        });
    }
};
