import AnalystHeaderSVG from '@/assets/logos/thematic-analyst-header.svg';
import { useGlobalState } from '@/components/global/global-state';
import { NavigationLink } from '@/components/global/link';

const Footer = () => {
    const { globalState } = useGlobalState();
    const { currentUser } = globalState;

    return (
        <footer className="site-footer bg-brand-black-alt dark w-full flex flex-col grow items-center justify-end gap-4 py-4 z-10">
            <div className="mx-auto">
                <AnalystHeaderSVG width="240px" />
            </div>
            <div className="flex gap-8 justify-between">
                <NavigationLink
                    href="https://gothematic.notion.site/Thematic-Analyst-Current-Capabilities-86b35ab89ce2420ca1ef4c294dda1941"
                    target="_blank"
                >
                    Current capabilities
                </NavigationLink>
                {/* TODO: verify that we are attaching the email correctly */}
                <NavigationLink
                    href={`https://j40jfo1tlke.typeform.com/to/rM7OQATy#email=${encodeURIComponent(
                        currentUser?.email || ''
                    )}`}
                    target="_blank"
                >
                    Feedback
                </NavigationLink>
                <NavigationLink
                    href="https://calendly.com/brian-thematic"
                    target="_blank"
                >
                    Schedule a feedback session
                </NavigationLink>
            </div>
        </footer>
    );
};

export default Footer;
