export enum Pages {
    HOME = 'homepage',
    ABOUT = 'about',
    BLOG = 'blog',
    BLOG_DETAIL = 'blog_detail',
    PRICING = 'pricing',
    PRODUCT_OVERVIEW_LP = 'product_overview_lp',
    ASSET_MANAGEMENT_PRODUCT_DEVELOPEMENT_LP = 'asset_management_product_development_lp',
    PRODUCT_EVALUATION_LP = 'product_evaluation_lp',
    PLATFORM_START = 'platform_start',
}

export enum Sections {
    HERO = 'hero',
    PAGE_BOTTOM_CALLOUT = 'page_bottom_callout',
}

export const FeatureFlags = {
    NEW_SECTIONED_COMPANY_EVALUATION_PAGE: 'new-sectioned-company-evaluation-page',
};
