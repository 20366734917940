import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

const Notifications = () => {
    return (
        <ToastContainer
            position="bottom-center"
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
};

export default Notifications;
