import { useState } from 'react';
import { toast } from 'react-toastify';

import OrganizationSelector from '@/components/admin/organization-selector';
import { Label } from '@/components/dom/form-elements';
import Button from '@/components/global/button';
import { useCurrentOrganization } from '@/components/global/current-organization';
import { useGlobalState } from '@/components/global/global-state';
import Icon from '@/components/global/icon';
import cn from '@/lib/cn';

export const CurrentOrganizationSelector = () => {
    const { globalState } = useGlobalState();
    const { currentUser } = globalState;

    const [hidden, setHidden] = useState(false);

    const { canSelectCurrentOrganization, currentOrganizationId, setCurrentOrganizationId } = useCurrentOrganization();

    if (!canSelectCurrentOrganization || hidden) return <></>;

    const organizationMismatch = currentUser && currentUser.primaryOrganization?.id !== currentOrganizationId;

    return (
        <>
            <div
                className={cn(
                    'sticky top-0 w-full z-1000 flex items-center',
                    organizationMismatch ? 'bg-brand-danger' : 'bg-brand-navy'
                )}
            >
                <div className="flex grow">
                    <Label
                        position="left"
                        className="px-2 text-white"
                        text={`Operating as ${organizationMismatch ? 'NON-PRIMARY' : ''} Team...`}
                    >
                        <OrganizationSelector
                            selectedOrganizationId={currentOrganizationId}
                            defaultOrganizationId={currentUser?.primaryOrganization?.id}
                            onSelect={(organizationId: string) => {
                                setCurrentOrganizationId(organizationId);

                                toast.success(`Updated your current Team to organization_id: ${organizationId}`);
                            }}
                        />
                        {organizationMismatch && currentUser.primaryOrganization?.id && (
                            <Button
                                // TODO: TRACKING
                                type="action"
                                color="transparent"
                                onClick={() => {
                                    if (!currentUser.primaryOrganization?.id) return;

                                    setCurrentOrganizationId(currentUser.primaryOrganization?.id);

                                    toast.success(
                                        `Updated your current Team back to organization_id: ${currentUser.primaryOrganization?.id}`
                                    );
                                }}
                            >
                                <Icon
                                    type="history"
                                    color="white"
                                />
                            </Button>
                        )}
                    </Label>
                </div>
                <Button
                    // TODO: TRACKING
                    type="action"
                    color="transparent"
                    onClick={() => {
                        setHidden(true);

                        toast.success('Successfully hid Team selector; refresh page to restore it');
                    }}
                >
                    <Icon
                        type="close"
                        color="white"
                    />
                </Button>
            </div>
        </>
    );
};
