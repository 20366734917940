import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';

import { UserAvatar } from '@/components/global/avatar';
import Button from '@/components/global/button';
import { useCurrentOrganization } from '@/components/global/current-organization';
import { useGlobalState } from '@/components/global/global-state';
import Icon from '@/components/global/icon';
import SiteLink, { DEFAULT_SITE_LINK_STYLING } from '@/components/global/site-link';
import useOutsideClick from '@/helpers/hooks/useOutsideClick';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { logOutCurrentUser } from '@/helpers/logout';
import cn from '@/lib/cn';
import { type ButtonClickTrackingProperties } from '@/types/tracking';

const LINK_CLASSES =
    'text-analyst-gray font-brand-md visited:text-analyst-gray visited:underline hover:text-analyst-purple transition-colors px-4 py-1.5 rounded-full hover:bg-analyst-lavender hover:text-thematic-purple whitespace-nowrap';

interface UserProfileDropdownProps {
    className?: string;
    menuClassName?: string;
    toggleClassName?: string;
    hideLogout?: boolean;
    trackingProperites: Pick<ButtonClickTrackingProperties, 'section'>;
}

const UserProfileDropdown = ({
    className,
    menuClassName,
    toggleClassName,
    hideLogout = false,
    trackingProperites = {} as ButtonClickTrackingProperties,
}: UserProfileDropdownProps) => {
    const { categories, components, eventTypes } = usePosthogTracking();
    const ref = useRef(null);
    const router = useRouter();
    const { globalState, setGlobalState } = useGlobalState();
    const [showDropdown, setShowDropdown] = useState(false);
    const hideDropdown = () => setShowDropdown(false);
    const toggleDropdown = () => setShowDropdown(!showDropdown);
    const { currentOrganizationIndicesUrl, currentOrganizationMembersUrl, currentOrganizationProfileUrl } =
        useCurrentOrganization();

    useOutsideClick(ref, hideDropdown);

    return (
        <div
            ref={ref}
            className={cn(
                'hover:cursor-pointer px-2 rounded-full border-2 border-analyst-gray h-12 flex items-center justify-centerm bg-white',
                className
            )}
        >
            <div className="relative">
                <Button
                    type="action"
                    color="transparent"
                    className="border-0 p-0 flex items-center gap-4"
                    onClick={toggleDropdown}
                    tracking={{
                        eventType: showDropdown ? eventTypes.CLOSE_DROPDOWN_MENU : eventTypes.OPEN_DROPDOWN_MENU,
                        trackingProperties: {
                            category: categories.DROPDOWN_MENU_ACTION,
                            component: components.USER_PROFILE_DROPDOWN_TOGGLE,
                            ...trackingProperites,
                        },
                    }}
                >
                    <UserAvatar
                        url={globalState.currentUser?.avatarUrl || ''}
                        name={globalState.currentUser?.name || ''}
                        className="w-9 h-9 mb-0 text-base rounded-full overflow-hidden"
                    />

                    <Icon
                        type="caret"
                        color="analystPurple"
                        size="xxs"
                        className={cn('-rotate-90 -translate-x-1/2', toggleClassName)}
                    />
                </Button>
                {showDropdown && (
                    <menu
                        className={cn(
                            'bg-analyst-white shadow-md border border-brand-gray-med min-w-fit absolute py-4 px-2 top-14 left-0 md:right-0 md:left-auto rounded-lg flex flex-col animate-fadeIn',
                            menuClassName
                        )}
                    >
                        {currentOrganizationIndicesUrl ? (
                            <SiteLink
                                key="indices-link"
                                url={currentOrganizationIndicesUrl}
                                className={LINK_CLASSES}
                            >
                                Dashboard
                            </SiteLink>
                        ) : null}
                        {currentOrganizationMembersUrl ? (
                            <SiteLink
                                key="members-link"
                                url={currentOrganizationMembersUrl}
                                className={LINK_CLASSES}
                            >
                                Team members
                            </SiteLink>
                        ) : null}
                        {currentOrganizationProfileUrl ? (
                            <SiteLink
                                key="profile-link"
                                url={currentOrganizationProfileUrl}
                                className={LINK_CLASSES}
                            >
                                Team profile
                            </SiteLink>
                        ) : null}
                        <SiteLink
                            key="account-link"
                            url="/my-account/profile"
                            className={LINK_CLASSES}
                        >
                            My account
                        </SiteLink>
                        {!hideLogout && (
                            <button
                                onClick={() => logOutCurrentUser({ onSuccess: router.refresh, setGlobalState })}
                                className={cn(DEFAULT_SITE_LINK_STYLING, LINK_CLASSES)}
                                key="logout"
                            >
                                Log out
                            </button>
                        )}
                    </menu>
                )}
            </div>
        </div>
    );
};

export default UserProfileDropdown;
