'use client';

import React, { createContext, useContext } from 'react';

import { makeUseContextLocalStorage } from '@/lib/contextLocalStorage';

export interface FeatureFlagsInterface {
    dummyFlag: boolean;
    indexBuilderShowInstrumentPercentPriceDifference: boolean;
    myTeamCompareEditLoadSecurities: boolean;
    myTeamCompareIndicesEnabled: boolean;
    recommendSimilarInIndexBuilder: boolean;
    adminStateFlags: Array<{
        label: string;
        value: boolean;
        setValue: ReactStateSetter<boolean>;
    }>;
}

const FeatureFlagsContext = createContext({} as FeatureFlagsInterface);

const FeatureFlagsProvider = ({ children }: { children: React.ReactNode }) => {
    const useFeatureFlagsLocalStorage = makeUseContextLocalStorage('featureFlags');

    // Set a dummy flag to show an example of how to use this, in the absence of any real flags
    const [dummyFlag, setDummyFlag] = useFeatureFlagsLocalStorage('dummyFlag', false);
    const [indexBuilderShowInstrumentPercentPriceDifference, setIndexBuilderShowInstrumentPercentPriceDifference] =
        useFeatureFlagsLocalStorage('indexBuilderShowInstrumentPercentPriceDifference', false);
    const [myTeamCompareIndicesEnabled, setMyTeamCompareIndicesEnabled] = useFeatureFlagsLocalStorage(
        'myTeamCompareIndicesEnabled',
        false
    );
    const [recommendSimilarInIndexBuilder, setRecommendSimilarInIndexBuilder] = useFeatureFlagsLocalStorage(
        'recommendSimilarInIndexBuilder',
        false
    );
    const [myTeamCompareEditLoadSecurities, setMyTeamCompareEditLoadSecurities] = useFeatureFlagsLocalStorage(
        'myTeamCompareEditLoadSecurities',
        false
    );

    const adminStateFlags = (
        [
            ['DUMMY', dummyFlag, setDummyFlag],
            [
                'Show Instrument Percent Price Difference in Index Builder',
                indexBuilderShowInstrumentPercentPriceDifference,
                setIndexBuilderShowInstrumentPercentPriceDifference,
            ],
            ['Enable comparison in My Team - Indices', myTeamCompareIndicesEnabled, setMyTeamCompareIndicesEnabled],
            [
                'Recommend similar companies in Index Builder',
                recommendSimilarInIndexBuilder,
                setRecommendSimilarInIndexBuilder,
            ],
            [
                'Enable Edit - Load Securities in My Team - Indices',
                myTeamCompareEditLoadSecurities,
                setMyTeamCompareEditLoadSecurities,
            ],
        ] as Array<[string, boolean, ReactStateSetter<boolean>]>
    ).map(([label, value, setValue]) => {
        return { label, setValue, value };
    });

    return (
        <FeatureFlagsContext.Provider
            value={{
                adminStateFlags,
                dummyFlag,
                indexBuilderShowInstrumentPercentPriceDifference,
                myTeamCompareEditLoadSecurities,
                myTeamCompareIndicesEnabled,
                recommendSimilarInIndexBuilder,
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};

const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext);
    if (!context) {
        throw new Error('useFeatureFlags must be used within a FeatureFlagsContext');
    }
    return context;
};

export { FeatureFlagsProvider, useFeatureFlags };
